export const IpAddressActionTypes = {
    GET_IP_ADDRESS_FIRST: '@@/layout/GET_IP_ADDRESS_ADMIN_ND_SITE_ADMIN',
    GET_IP_ADDRESS_LOADING: '@@/layout/GET_IP_ADDRESS_LOADING',
    GET_IP_ADDRESS_SUCCESS: '@@/layout/GET_IP_ADDRESS_SUCCESS',
    GET_IP_ADDRESS_ERROR: '@@/layout/GET_IP_ADDRESS_ERROR',

    GET_IP_ADDRESS_INFO_FIRST: '@@/layout/GET_IP_ADDRESS_INFO_ADMIN_ND_SITE_ADMIN',
    GET_IP_ADDRESS_INFO_LOADING: '@@/layout/GET_IP_ADDRESS_INFO_LOADING',
    GET_IP_ADDRESS_INFO_SUCCESS: '@@/layout/GET_IP_ADDRESS_INFO_SUCCESS',
    GET_IP_ADDRESS_INFO_ERROR: '@@/layout/GET_IP_ADDRESS_INFO_ERROR',


    STATUS_IP_ADDRESS_FIRST: '@@/layout/STATUS_IP_ADDRESS_FIRST',
    STATUS_IP_ADDRESS_LOADING: '@@/layout/STATUS_IP_ADDRESS_LOADING',
    STATUS_IP_ADDRESS_SUCCESS: '@@/layout/STATUS_IP_ADDRESS_SUCCESS',
    STATUS_IP_ADDRESS_ERROR: '@@/layout/STATUS_IP_ADDRESS_ERROR',
    STATUS_IP_ADDRESS_RESET: '@@/layout/STATUS_IP_ADDRESS_RESET',

    //  ip address scan 

    GET_IP_ADDRESS_SCAN_FIRST: '@@/layout/GET_IP_ADDRESS_SCAN_ADMIN_ND_SITE_ADMIN',
    GET_IP_ADDRESS_SCAN_LOADING: '@@/layout/GET_IP_ADDRESS_SCAN_LOADING',
    GET_IP_ADDRESS_SCAN_SUCCESS: '@@/layout/GET_IP_ADDRESS_SCAN_SUCCESS',
    GET_IP_ADDRESS_SCAN_ERROR: '@@/layout/GET_IP_ADDRESS_SCAN_ERROR',


    GET_IP_ADDRESS_SCAN_LIST_FIRST: '@@/layout/GET_IP_ADDRESS_SCAN_LIST_ADMIN_ND_SITE_ADMIN',
    GET_IP_ADDRESS_SCAN_LIST_LOADING: '@@/layout/GET_IP_ADDRESS_SCAN_LIST_LOADING',
    GET_IP_ADDRESS_SCAN_LIST_SUCCESS: '@@/layout/GET_IP_ADDRESS_SCAN_LIST_SUCCESS',
    GET_IP_ADDRESS_SCAN_LIST_ERROR: '@@/layout/GET_IP_ADDRESS_SCAN_LIST_ERROR',

    GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_FIRST: '@@/layout/GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_ADMIN_ND_SITE_ADMIN',
    GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_LOADING: '@@/layout/GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_LOADING',
    GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_SUCCESS: '@@/layout/GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_SUCCESS',
    GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_ERROR: '@@/layout/GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_ERROR',


    ADD_IP_ADDRESS_SCAN_FIRST: '@@/layout/ADD_IP_ADDRESS_SCAN_FIRST',
    ADD_IP_ADDRESS_SCAN_LOADING: '@@/layout/ADD_IP_ADDRESS_SCAN_LOADING',
    ADD_IP_ADDRESS_SCAN_SUCCESS: '@@/layout/ADD_IP_ADDRESS_SCAN_SUCCESS',
    ADD_IP_ADDRESS_SCAN_ERROR: '@@/layout/ADD_IP_ADDRESS_SCAN_ERROR',
    ADD_IP_ADDRESS_SCAN_RESET: '@@/layout/ADD_IP_ADDRESS_SCAN_RESET',

    EDIT_IP_ADDRESS_SCAN_FIRST: '@@/layout/EDIT_IP_ADDRESS_SCAN_FIRST',
    EDIT_IP_ADDRESS_SCAN_LOADING: '@@/layout/EDIT_IP_ADDRESS_SCAN_LOADING',
    EDIT_IP_ADDRESS_SCAN_SUCCESS: '@@/layout/EDIT_IP_ADDRESS_SCAN_SUCCESS',
    EDIT_IP_ADDRESS_SCAN_ERROR: '@@/layout/EDIT_IP_ADDRESS_SCAN_ERROR',
    EDIT_IP_ADDRESS_SCAN_RESET: '@@/layout/EDIT_IP_ADDRESS_SCAN_RESET',

    EMPTY_STATE_RESCHEDULE_IP_FIRST: '@@/layout/EMPTY_STATE_RESCHEDULE_IP_FIRST',

    ADD_IP_FOR_SCANNING_FIRST: '@@/layout/ADD_IP_FOR_SCANNING_FIRST',
    ADD_IP_FOR_SCANNING_LOADING: '@@/layout/ADD_IP_FOR_SCANNING_LOADING',
    ADD_IP_FOR_SCANNING_SUCCESS: '@@/layout/ADD_IP_FOR_SCANNING_SUCCESS',
    ADD_IP_FOR_SCANNING_ERROR: '@@/layout/ADD_IP_FOR_SCANNING_ERROR',
    ADD_IP_FOR_SCANNING_RESET: '@@/layout/ADD_IP_FOR_SCANNING_RESET',


};
