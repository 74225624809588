import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminVChartGetApiEndPoint(params: any): any {
    const { companyId, search, limit, page } = params?.data;
    const companyIdToSend = companyId || 1; // If companyId is not available, default to 1
    return api.get(`${URL.ASSETS_GET}companyid=${companyIdToSend}&search=${search}&limit=${limit}&page=${page}`);
}

function adminNdSiteAdminVChartAddApiEndPoint(params: any): any {
    const AddVChartData = params?.data;
    return api.create(URL.ASSETS_Add, AddVChartData);
}

function adminNdSiteAdminVChartEditApiEndPoint(params: any): any {
    const AddVChartData = params?.data;
    return api.update(URL.ASSETS_Edit, AddVChartData);
}

function adminNdSiteAdminVChartStatusApiEndPoint(params: any): any {
    const statusVChartData = params?.data;
    return api.create(URL.ASSETS_Status, statusVChartData);
}

function adminNdSiteAdminVChartCsvApiEndPoint(params: any): any {
    const csvVChartData = params?.data;
    return api.create(URL.ASSETS_CSV, csvVChartData);
}

export {
    adminNdSiteAdminVChartGetApiEndPoint,
    adminNdSiteAdminVChartAddApiEndPoint,
    adminNdSiteAdminVChartEditApiEndPoint,
    adminNdSiteAdminVChartStatusApiEndPoint,
    adminNdSiteAdminVChartCsvApiEndPoint,
};
