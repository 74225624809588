import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function emailVerifyEndPoint(params: any): any {
    const { id } = params?.id;
    return api.create(`${URL.EMAIL_VERIFY}${id}`);
}

function resetPasswordConfirmEndPoint(params: any): any {
    const { id, password, confirm_password } = params.data;

    return api.create(`${URL.RESET_CONFIRM_PASSWORD}${id}`, {
        password: password,
        confirm_password: confirm_password
    });
}

export { emailVerifyEndPoint, resetPasswordConfirmEndPoint };
