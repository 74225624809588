export const UsersMyDevicesActionTypes = {
    GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER: '@@/layout/GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER',
    GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_LOADING: '@@/layout/GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_LOADING',
    GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_SUCCESS: '@@/layout/GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_SUCCESS',
    GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_ERROR: '@@/layout/GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_ERROR',

    DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER: '@@/layout/DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER',
    DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_LOADING: '@@/layout/DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_LOADING',
    DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_SUCCESS: '@@/layout/DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_SUCCESS',
    DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_ERROR: '@@/layout/DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_ERROR',
    DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_RESET: '@@/layout/DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_RESET'

};
