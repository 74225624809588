import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function getDocuments(params: { id: string, companyId: string, status?: string }): any {
    const { companyId, status, skip = '' } = params;
    return api.get(`${URL.GET_DOCUMENTS}?companyId=${companyId}&status=${status}&skip=${skip}`);
}

function AddDocuments(params: any): any {
    const documentAddData = params?.data;
    return api.create(URL.ADD_DOCUMENTS, documentAddData);
}
function AddDocumentsStatus(params: any): any {
    const documentStatus = params?.data;
    return api.create(URL.ADD_DOCUMENTS_STATUS, documentStatus);
}

function deleteDocuments(params: any): any {
    const id = params?.id; // Extract id from params
    return api.create(URL.DELETE_DOCUMENTS, id); // Send id in the request body
}

export {
    getDocuments,
    AddDocuments,
    AddDocumentsStatus,
    deleteDocuments
};
