import { AdminNdSiteAdminGroupsActionTypes } from './constants';

export const getGroupsListAdminNdSiteAdminActions = (id): LayoutAction => ({
    type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN,
    id,
});

export const addGroupsAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN,
    data,
});

export const editGroupsAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN,
    data,
});

export const statusGroupsAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN,
    data,
});

export const deleteGroupsAdminNdSiteAdminActions = (id): LayoutAction => ({
    type: AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN,
    id,
});

export const getGroupsMngmntAdminNdSiteAdminActions = (id): LayoutAction => ({
    type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN,
    id,
});

export const dataGroupsMngmntEmptyActions = (): LayoutAction => ({
    type: AdminNdSiteAdminGroupsActionTypes.DATA_MNGMNT_EMPTY,
});

export const getGroupsUserAdminNdSiteAdminActions = (id): LayoutAction => ({
    type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN,
    id,
});

export const accountUsersEmptyActions = (): LayoutAction => ({
    type: AdminNdSiteAdminGroupsActionTypes.USERS_ACCOUNT_EMTPTY,

});

export const assignGroupsDeleteActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_FIRST,
    data
});


export const stateEmptydAction = (): LayoutAction => ({
    type: AdminNdSiteAdminGroupsActionTypes.STATE_EMPTY_INTIAL_STATE,

});







