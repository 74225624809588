// @flow
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import {
    myDevicesGetBySelectAssetsReducer,
    myDevicesDeleteBySelectAssetsReducer,
} from './user/devices/myDevices/reducers';
import {
    getRiskdashboardReducer,
    getRiskVulnerabilityReducer,
    riskRemedationReducer,
    japaneseRemedationReducer,
} from './adminNdSideAdmin/riskDashboard/reducers';
import {
    addDevicesGetBySelectAssetsReducer,
    addDevicesPostBySelectAssetsReducer,
} from './user/devices/addDevices/reducers';
import { getMyDataSourceByGroupsReducer } from './user/dataSources/myDataSources/reducers';
import { getCompanyAdminNdSiteAdminReducer } from './adminNdSideAdmin/company/reducers';
import {
    getInstanceAdminReducer,
    addInstanceAdminReducer,
    editInstanceAdminReducer,
    statusInstanceAdminReducer,
    deleteInstanceAdminNdSiteAdminReducer,
    addLicenceAdminReducer,
    activeUserAdminReducer,
    getInstancePlaneReducer,
} from './adminNdSideAdmin/instance/reducers';
import {
    getAssetsByCompanyIdAdminNdSiteAdminReducer,
    addAssetsByCompanyIdAdminNdSiteAdminReducer,
    editAssetsByCompanyIdAdminNdSiteAdminReducer,
    statusAssetsByCompanyIdAdminNdSiteAdminReducer,
    csvAssetsByCompanyIdAdminNdSiteAdminReducer,
    getSoftwareListReducer,
    deleteAssetsReducer
} from './adminNdSideAdmin/assets/reducers';
import {
    getVChartByCompanyIdAdminNdSiteAdminReducer,
    addVChartByCompanyIdAdminNdSiteAdminReducer,
    editVChartByCompanyIdAdminNdSiteAdminReducer,
    statusVChartByCompanyIdAdminNdSiteAdminReducer,
    csvVChartByCompanyIdAdminNdSiteAdminReducer
} from './adminNdSideAdmin/VulnerabilityChart/reducers'
import {
    getDataMngmntAdminNdSiteAdminReducer,
    addDataMngmntAdminNdSiteAdminReducer,
    editDataMngmntAdminNdSiteAdminReducer,
    csvDataMngmntAdminNdSiteAdminReducer,
    statusDataMngmntAdminNdSiteAdminReducer,
    assignGroupsDataMngmntAdminNdSiteAdminReducer,
    getGroupsDataMngmntAdminNdSiteAdminReducer,
    addGroupsDataMngmntAssetsAdminNdSiteAdminReducer,
} from './adminNdSideAdmin/dataManagement/reducers';
import {
    getAccountsAdminNdSiteAdminReducer,
    addAccountsAdminNdSiteAdminReducer,
    editAccountsAdminNdSiteAdminReducer,
    statusAccountsAdminNdSiteAdminReducer,
    getGroupsAccountsAdminNdSiteAdminReducer,
    assignGroupsAccountsAdminNdSiteAdminReducer,
    userPasswordResetAccountsAdminNdSiteAdminReducer,
} from './adminNdSideAdmin/accounts/reducers';
import { getLogsAdminReducer } from './adminNdSideAdmin/logs/reducers';
import {
    getGroupsListAdminNdSiteAdminReducer,
    addGroupsAdminNdSiteAdminReducer,
    editGroupsAdminNdSiteAdminReducer,
    statusGroupsAdminNdSiteAdminReducer,
    deleteGroupsAdminNdSiteAdminReducer,
    getGroupsMngmntAdminNdSiteAdminReducer,
    getGroupsUsersAdminNdSiteAdminReducer,
    assignGroupsDeleteAdminNdSiteAdminReducer,
} from './adminNdSideAdmin/groups/reducers';
import { getNewsFildReducer } from './user/dashboard/newsFild/reducers';
import { profileUpdateReducer, forgetPasswordReducer } from './adminNdSideAdmin/adminSetting/reducers';
import { putForcePasswordReducer } from './forcePassword/reducers';
import {
    postAddSofterAdminNdSiteAdminReducer,
    getSofterAdminNdSiteAdminReducer,
    getItDashboardReducer,
    dfltStDataITDshbrodReducer,
    postIpAddressAdminNdSiteAdminReducer,
    getItDashboardTotalSoftwareReducer,
    getSuperAdminDashboardReducer,
    postVulnerabilityReducer,
} from './adminNdSideAdmin/dashboard/addSoftware/reducers';
import {
    getSoftwareReducer,
    getTaskReducer,
    addTaskReducer,
    getScheduleRepeatReducer,
    getScanReducer,
    getPreviousScanReducer,
    getPreviousAssetReducer,
    addScanSchduleReducer,
    getSoftwareNameReducer,
    getUserMailReducer,
    getUserReducer,
    deleteSoftwareReducer,
    editScanScheduleReducer,
    statusSoftwareReducer,
    addSoftwareScanReducer,
} from './adminNdSideAdmin/software/reducers';
import {
    getTaskListReducer,
    getTaskStatusReducer,
    addStartTaskReducer,
    addCommentReducer,
} from './adminNdSideAdmin/task/reducers';
import { emailVerifyReducer, confirmResetPasswordReducer } from './adminNdSideAdmin/emailVerify/reducers';

// ip address....
import {
    getIpAddressReducer,
    getIpAddressInfoReducer,
    getIpAddressScanReducer,
    addScanScheduleIpReducer,
    editScanScheduleIpReducer,
    getIpAddressScanListReducer,
    getPreviousIpAddressScanListReducer,
    statusIpAddressReducer,
    addIpScanReducer,
} from './adminNdSideAdmin/IPAddress/reducers';
import { createAdminReducer, getAdminReducer, updateAdminReducer } from './adminNdSideAdmin/adminCreate/reducers';
import {
    addDocumentsReducer,
    addDocumentsStatusReducer,
    getDocumentsReducer,
    deleteDocumentsSiteAdminReducer,
} from './adminNdSideAdmin/documents/reducers';

export default (combineReducers({
    Auth,
    Layout,
    myDevicesGetBySelectAssetsReducer,
    addDevicesGetBySelectAssetsReducer,
    addDevicesPostBySelectAssetsReducer,
    myDevicesDeleteBySelectAssetsReducer,
    getMyDataSourceByGroupsReducer,
    getInstanceAdminReducer,
    addInstanceAdminReducer,
    editInstanceAdminReducer,
    statusInstanceAdminReducer,
    deleteInstanceAdminNdSiteAdminReducer,
    getInstancePlaneReducer,
    getCompanyAdminNdSiteAdminReducer,
    getAssetsByCompanyIdAdminNdSiteAdminReducer,
    addAssetsByCompanyIdAdminNdSiteAdminReducer,
    editAssetsByCompanyIdAdminNdSiteAdminReducer,
    statusAssetsByCompanyIdAdminNdSiteAdminReducer,
    csvAssetsByCompanyIdAdminNdSiteAdminReducer,
    getDataMngmntAdminNdSiteAdminReducer,
    addDataMngmntAdminNdSiteAdminReducer,
    editDataMngmntAdminNdSiteAdminReducer,
    csvDataMngmntAdminNdSiteAdminReducer,
    statusDataMngmntAdminNdSiteAdminReducer,
    assignGroupsDataMngmntAdminNdSiteAdminReducer,
    getGroupsDataMngmntAdminNdSiteAdminReducer,
    addGroupsDataMngmntAssetsAdminNdSiteAdminReducer,
    getAccountsAdminNdSiteAdminReducer,
    addAccountsAdminNdSiteAdminReducer,
    editAccountsAdminNdSiteAdminReducer,
    statusAccountsAdminNdSiteAdminReducer,
    getGroupsAccountsAdminNdSiteAdminReducer,
    assignGroupsAccountsAdminNdSiteAdminReducer,
    userPasswordResetAccountsAdminNdSiteAdminReducer,
    getLogsAdminReducer,
    getGroupsListAdminNdSiteAdminReducer,
    addGroupsAdminNdSiteAdminReducer,
    editGroupsAdminNdSiteAdminReducer,
    statusGroupsAdminNdSiteAdminReducer,
    deleteGroupsAdminNdSiteAdminReducer,
    getGroupsMngmntAdminNdSiteAdminReducer,
    getGroupsUsersAdminNdSiteAdminReducer,
    getNewsFildReducer,
    profileUpdateReducer,
    forgetPasswordReducer,
    putForcePasswordReducer,
    assignGroupsDeleteAdminNdSiteAdminReducer,
    postAddSofterAdminNdSiteAdminReducer,
    postIpAddressAdminNdSiteAdminReducer,
    getSofterAdminNdSiteAdminReducer,
    getItDashboardReducer,
    getItDashboardTotalSoftwareReducer,
    dfltStDataITDshbrodReducer,
    getSoftwareReducer,
    getTaskReducer,
    addTaskReducer,
    getTaskListReducer,
    getTaskStatusReducer,
    addStartTaskReducer,
    addCommentReducer,
    getScheduleRepeatReducer,
    getSoftwareNameReducer,
    getUserMailReducer,
    getUserReducer,
    getScanReducer,
    getPreviousScanReducer,
    getPreviousAssetReducer,
    emailVerifyReducer,
    deleteSoftwareReducer,
    editScanScheduleReducer,
    statusSoftwareReducer,
    addScanSchduleReducer,
    getIpAddressReducer,
    getIpAddressInfoReducer,
    getIpAddressScanReducer,
    addScanScheduleIpReducer,
    editScanScheduleIpReducer,
    getIpAddressScanListReducer,
    getPreviousIpAddressScanListReducer,
    statusIpAddressReducer,
    confirmResetPasswordReducer,
    addLicenceAdminReducer,
    activeUserAdminReducer,
    createAdminReducer,
    getAdminReducer,
    updateAdminReducer,
    getSuperAdminDashboardReducer,
    postVulnerabilityReducer,
    riskRemedationReducer,
    japaneseRemedationReducer,
    getRiskdashboardReducer,
    getRiskVulnerabilityReducer,
    addDocumentsReducer,
    addDocumentsStatusReducer,
    getDocumentsReducer,
    deleteDocumentsSiteAdminReducer,
    addSoftwareScanReducer,
    addIpScanReducer,
    getVChartByCompanyIdAdminNdSiteAdminReducer,
    addVChartByCompanyIdAdminNdSiteAdminReducer,
    editVChartByCompanyIdAdminNdSiteAdminReducer,
    statusVChartByCompanyIdAdminNdSiteAdminReducer,
    csvVChartByCompanyIdAdminNdSiteAdminReducer,
    getSoftwareListReducer,
    deleteAssetsReducer,
}): any);
