import { ForcePasswordActionTypes } from "./constants";

const FORCE_PASSWORD_INITIAL_STATE = {
    forcePassword: '',
    loading: false
}

const putForcePasswordReducer = (state = FORCE_PASSWORD_INITIAL_STATE, action) => {
    switch (action.type) {
        case ForcePasswordActionTypes.PUT_FORCE_PASSWORD_LOADING:
            return {
                forcePassword: state.forcePassword,
                loading: true
            }
        case ForcePasswordActionTypes.PUT_FORCE_PASSWORD_SUCCESS:
            return {
                forcePassword: action.payload,
                loading: false
            }
        case ForcePasswordActionTypes.PUT_FORCE_PASSWORD_ERROR:
            return {
                forcePassword: action.payload,
                loading: false,
                error: true
            }
        case ForcePasswordActionTypes.PUT_FORCE_PASSWORD_RESET:
            return FORCE_PASSWORD_INITIAL_STATE
        default: return state
    }
}

export { putForcePasswordReducer }