export const TaskActionTypes = {
    GET_TASK_LIST_FIRST: '@@/layout/GET_TASK_LIST_FIRST',
    GET_TASK_LIST_LOADING: '@@/layout/GET_TASK_LIST_LOADING',
    GET_TASK_LIST_SUCCESS: '@@/layout/GET_TASK_LIST_SUCCESS',
    GET_TASK_LIST_ERROR: '@@/layout/GET_TASK_LIST_ERROR',

    GET_TASK_STATUS_FIRST: '@@/layout/GET_TASK_STATUS_FIRST',
    GET_TASK_STATUS_LOADING: '@@/layout/GET_TASK_STATUS_LOADING',
    GET_TASK_STATUS_SUCCESS: '@@/layout/GET_TASK_STATUS_SUCCESS',
    GET_TASK_STATUS_ERROR: '@@/layout/GET_TASK_STATUS_ERROR',

    ADD_START_TASK_FIRST: '@@/layout/ADD_START_TASK_FIRST',
    ADD_START_TASK_LOADING: '@@/layout/ADD_START_TASK_LOADING',
    ADD_START_TASK_SUCCESS: '@@/layout/ADD_START_TASK_SUCCESS',
    ADD_START_TASK_ERROR: '@@/layout/ADD_START_TASK_ERROR',
    ADD_START_TASK_RESET: '@@/layout/ADD_START_TASK_RESET',
    
    ADD_COMMENT_FIRST: '@@/layout/ADD_COMMENT_FIRST',
    ADD_COMMENT_LOADING: '@@/layout/ADD_COMMENT_LOADING',
    ADD_COMMENT_SUCCESS: '@@/layout/ADD_COMMENT_SUCCESS',
    ADD_COMMENT_ERROR: '@@/layout/ADD_COMMENT_ERROR',
    ADD_COMMENT_RESET: '@@/layout/ADD_COMMENT_RESET',


}