import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { ForcePasswordActionTypes } from './constants';
import {
    putForcePasswordApiEndPoint,
} from './api';

// start cash day Report

function* callPutForcePassword(data) {
    try {
        yield put({
            type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_LOADING,
            payload: {},
        });
        const response = yield call(putForcePasswordApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_ERROR,
            payload: error,
        });
        yield put({
            type: ForcePasswordActionTypes.PUT_FORCE_PASSWORD_RESET,
            payload: {},
        });
    }
}


// end cash day Report List

export function* putForcePasswordAction(): any {
    yield takeEvery(
        ForcePasswordActionTypes.PUT_FORCE_PASSWORD_FIRST,
        callPutForcePassword
    );
}


function* putForcePasswordSaga(): any {
    yield all([
        fork(putForcePasswordAction)
    ]);
}

export default putForcePasswordSaga;
