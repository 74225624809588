export const EmailVerifyActionTypes = {
    EMAIL_VERIFY_WITH_TOKEN_FIRST: '@@/layout/EMAIL_VERIFY_WITH_TOKEN_FIRST',
    EMAIL_VERIFY_WITH_TOKEN_LOADING: '@@/layout/EMAIL_VERIFY_WITH_TOKEN_LOADING',
    EMAIL_VERIFY_WITH_TOKEN_SUCCESS: '@@/layout/EMAIL_VERIFY_WITH_TOKEN_SUCCESS',
    EMAIL_VERIFY_WITH_TOKEN_ERROR: '@@/layout/EMAIL_VERIFY_WITH_TOKEN_ERROR',

    RESET_PASSWORD_WITH_TOKEN_FIRST: '@@/layout/RESET_PASSWORD_WITH_TOKEN_FIRST',
    RESET_PASSWORD_WITH_TOKEN_LOADING: '@@/layout/RESET_PASSWORD_WITH_TOKEN_LOADING',
    RESET_PASSWORD_WITH_TOKEN_SUCCESS: '@@/layout/RESET_PASSWORD_WITH_TOKEN_SUCCESS',
    RESET_PASSWORD_WITH_TOKEN_ERROR: '@@/layout/RESET_PASSWORD_WITH_TOKEN_ERROR',
};
