export const AdminNdSiteAdminGroupsActionTypes = {
    GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN',
    GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_ERROR',

    ADD_GROUPS_ADMIN_ND_SITE_ADMIN: '@@/layout/ADD_GROUPS_ADMIN_ND_SITE_ADMIN',
    ADD_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/ADD_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING',
    ADD_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/ADD_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ADD_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/ADD_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR',
    ADD_GROUPS_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/ADD_GROUPS_ADMIN_ND_SITE_ADMIN_RESET',

    EDIT_GROUPS_ADMIN_ND_SITE_ADMIN: '@@/layout/EDIT_GROUPS_ADMIN_ND_SITE_ADMIN',
    EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING',
    EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS',
    EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR',
    EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_RESET',

    STATUS_GROUPS_ADMIN_ND_SITE_ADMIN: '@@/layout/STATUS_GROUPS_ADMIN_ND_SITE_ADMIN',
    STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING',
    STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS',
    STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR',
    STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_RESET',

    DELETE_GROUPS_ADMIN_ND_SITE_ADMIN: '@@/layout/DELETE_GROUPS_ADMIN_ND_SITE_ADMIN',
    DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING',
    DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS',
    DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR',
    DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_RESET',

    GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN',
    GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR',
    GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET',
    DATA_MNGMNT_EMPTY: '@@/layout/DATA_MNGMNT_EMPTY',
    DATA_MNGMNT_EMPTY_SUCCESS: '@@/layout/DATA_MNGMNT_EMPTY_SUCCESS',



    GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN',
    GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_ERROR',
    GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_RESET',
    USERS_ACCOUNT_EMTPTY: '@@/layout/USERS_ACCOUNT_EMTPTY',
    USERS_ACCOUNT_EMTPTY_SUCCESS: '@@/layout/USERS_ACCOUNT_EMTPTY_SUCCESS',


    ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_FIRST: '@@/layout/ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_FIRST',
    ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_LOADING',
    ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_ERROR',
    ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_RESET',


    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',


};
