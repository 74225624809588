import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { UsersMyDevicesActionTypes } from './constants';
import { userMyDevicesGetAssetsEndPoint, userMyDevicesDeleteAssetsEndPoint } from './api';


// start cash day Report

function* callMyDevicesGetBySelectAssets() {
    try {
        yield put({
            type: UsersMyDevicesActionTypes.GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_LOADING,
            payload: {},
        });
        const response = yield call(userMyDevicesGetAssetsEndPoint);
        if (response.data.status) {
            yield put({
                type: UsersMyDevicesActionTypes.GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: UsersMyDevicesActionTypes.GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: UsersMyDevicesActionTypes.GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_ERROR,
            payload: error,
        });
    }
}

function* callMyDevicesDeleteBySelectAssets(id) {
    try {
        yield put({
            type: UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_LOADING,
            payload: {},
        });
        const response = yield call(userMyDevicesDeleteAssetsEndPoint, id);
        if (response.data.status) {
            yield put({
                type: UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_ERROR,
            payload: error,
        });
        yield put({
            type: UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_RESET,
            payload: {},
        });
    }
}
// end cash day Report List


export function* getMyDevicesGetByAssetsAction(): any {
    yield takeEvery(UsersMyDevicesActionTypes.GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER, callMyDevicesGetBySelectAssets);
}

export function* deleteMyDevicesDeleteByAssetsAction(): any {
    yield takeEvery(UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER, callMyDevicesDeleteBySelectAssets);
}


function* getMyDevicesGetByAssetsSaga(): any {
    yield all([
        fork(getMyDevicesGetByAssetsAction), fork(deleteMyDevicesDeleteByAssetsAction)
    ]);
}

export default getMyDevicesGetByAssetsSaga;
