import { UsersMyDevicesActionTypes } from "./constants";

export const myDevicesGetByAssetsAction = (): LayoutAction => ({
    type: UsersMyDevicesActionTypes.GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER,
    payload: null,
});

export const myDevicesDeleteByAssetsAction = (id): LayoutAction => ({
    type: UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER,
    payload: id,
});