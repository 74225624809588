import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminGetGroupsListEndPoint(params: any): any {
    const { id, search, page, limit } = params?.id;
    return api.get(`${URL.GROUPS_LIST_GET}id=${id}&search=${search}&page=${page}&limit=${limit}`);
}

function adminNdSiteAdminAddGroupstEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.GROUPS_ADD, data);
}

function adminNdSiteAdminEditGroupstEndPoint(params: any): any {
    const { data } = params;
    return api.update(URL.GROUPS_EDIT, data);
}

function adminNdSiteAdminStatusGroupstEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.GROUPS_STATUS, data);
}

function adminNdSiteAdminDeleteGroupstEndPoint(params: any): any {
    const { id } = params;
    return api.delete(`${URL.GROUPS_DELETE}${id}`);
}

function adminNdSiteAdminGetGroupMngmntEndPoint(params: any): any {
    const { id } = params?.id;
    return api.get(`${URL.GROUPS_GET_MNGMNT}${id}`);
}

function adminNdSiteAdminGetGroupUsersEndPoint(params: any): any {
    const { id } = params?.id;
    return api.get(`${URL.GROUPS_GET_USERS}${id}`);
}

function adminNdSiteAdminAssignGroupsDeleteEndPoint(params: any): any {
    const { groupid, id, types } = params?.data;
    return api.delete(`${URL.ASSIGN_GROUPS_DELETE}${groupid}/${id}/${types}`);
}

export {
    adminNdSiteAdminGetGroupsListEndPoint,
    adminNdSiteAdminAddGroupstEndPoint,
    adminNdSiteAdminEditGroupstEndPoint,
    adminNdSiteAdminStatusGroupstEndPoint,
    adminNdSiteAdminDeleteGroupstEndPoint,
    adminNdSiteAdminGetGroupMngmntEndPoint,
    adminNdSiteAdminGetGroupUsersEndPoint,
    adminNdSiteAdminAssignGroupsDeleteEndPoint,
};
