import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminGetInstanceEndPoint(params: any): any {
    const { page, showLimit, searchInstance } = params?.data;
    return api.get(`${URL.INSTANCE_GET}limits=${showLimit}&page=${page}&search=${searchInstance}`);
}

function getInstancePlansEndPoint(): any {
    return api.get(URL.GET_INSTANCE_PLANS);
}

function adminNdSiteAdminAddInstanceEndPoint(params: any): any {
    const instanceAddData = params?.data;
    return api.create(URL.INSTANCE_ADD, instanceAddData);
}

function adminNdSiteAdminEditInstanceEndPoint(params: any): any {
    const instanceEditData = params?.data;
    return api.update(URL.INSTANCE_EDIT, instanceEditData);
}

function adminNdSiteAdminStatusInstanceEndPoint(params: any): any {
    const instanceStatusData = params?.data;
    return api.create(URL.INSTANCE_STATUS, instanceStatusData);
}

function adminNdSiteAdminDeleteInstanceEndPoint(params: any): any {
    const { id } = params?.id;
    return api.create(`${URL.INSTANCE_DELETE}${id}`);
}
function AddLicenceEndPoint(params: any): any {
    const LicenceAddData = params?.data;
    return api.create(URL.LICENCE_ADD, LicenceAddData);
}

function activeEndPoint(params: any): any {
    const activeUser = params?.data;
    return api.create(URL.ACTIVE_USER, activeUser);
}

export {
    adminNdSiteAdminGetInstanceEndPoint,
    adminNdSiteAdminAddInstanceEndPoint,
    adminNdSiteAdminEditInstanceEndPoint,
    adminNdSiteAdminStatusInstanceEndPoint,
    adminNdSiteAdminDeleteInstanceEndPoint,
    AddLicenceEndPoint,
    activeEndPoint,
    getInstancePlansEndPoint
};
