import { AdminNdSiteAdminDataMngntActionTypes } from './constants';

const GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    getDataMngmntAdminNdSiteAdmin: [],
    loading: false,
};

const ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    addDataMngmntAdminNdSiteAdmin: [],
    loading: false,
};

const EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    editDataMngmntAdminNdSiteAdmin: [],
    loading: false,
};

const CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    csvDataMngmntAdminNdSiteAdmin: [],
    loading: false,
};

const STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    statusDataMngmntAdminNdSiteAdmin: [],
    loading: false,
};

const ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    assignGroupsDataMngmntAdminNdSiteAdmin: [],
    loading: false,
};

const GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    getGroupsDataMngmntAdminNdSiteAdmin: [],
    loading: false,
};

const ADD_GROUPS_DATA_MNGMNT_ASSETS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    addGroupsDataMngmntAssetsAdminNdSiteAdmin: [],
    loading: false,
};

const getDataMngmntAdminNdSiteAdminReducer = (state = GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminDataMngntActionTypes.GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getDataMngmntAdminNdSiteAdmin: state.getDataMngmntAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminDataMngntActionTypes.GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE

        default:
            return state;
    }
};

const addDataMngmntAdminNdSiteAdminReducer = (state = ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                addDataMngmntAdminNdSiteAdmin: state.addDataMngmntAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                addDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                addDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET:
            return ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const editDataMngmntAdminNdSiteAdminReducer = (state = EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                editDataMngmntAdminNdSiteAdmin: state.editDataMngmntAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                editDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                editDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET:
            return ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const csvDataMngmntAdminNdSiteAdminReducer = (state = CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                csvDataMngmntAdminNdSiteAdmin: state.csvDataMngmntAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                csvDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                csvDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET:
            return CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const statusDataMngmntAdminNdSiteAdminReducer = (
    state = STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                statusDataMngmntAdminNdSiteAdmin: state.statusDataMngmntAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                statusDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                statusDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET:
            return STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const assignGroupsDataMngmntAdminNdSiteAdminReducer = (
    state = ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                assignGroupsDataMngmntAdminNdSiteAdmin: state.assignGroupsDataMngmntAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                assignGroupsDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                assignGroupsDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const getGroupsDataMngmntAdminNdSiteAdminReducer = (
    state = GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getGroupsDataMngmntAdminNdSiteAdmin: state.getGroupsDataMngmntAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getGroupsDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getGroupsDataMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const addGroupsDataMngmntAssetsAdminNdSiteAdminReducer = (
    state = ADD_GROUPS_DATA_MNGMNT_ASSETS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                addGroupsDataMngmntAssetsAdminNdSiteAdmin: state.addGroupsDataMngmntAssetsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                addGroupsDataMngmntAssetsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                addGroupsDataMngmntAssetsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_RESET:
            return ADD_GROUPS_DATA_MNGMNT_ASSETS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    getDataMngmntAdminNdSiteAdminReducer,
    addDataMngmntAdminNdSiteAdminReducer,
    editDataMngmntAdminNdSiteAdminReducer,
    csvDataMngmntAdminNdSiteAdminReducer,
    statusDataMngmntAdminNdSiteAdminReducer,
    assignGroupsDataMngmntAdminNdSiteAdminReducer,
    getGroupsDataMngmntAdminNdSiteAdminReducer,
    addGroupsDataMngmntAssetsAdminNdSiteAdminReducer,
};
