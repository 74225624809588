import { SoftwareActionTypes } from './constants';

export const getSoftwareActions = (data): LayoutAction => ({
    type: SoftwareActionTypes.GET_SOFTWARE_FIRST,
    data,
});

export const getTaskActions = (data): LayoutAction => ({
    type: SoftwareActionTypes.GET_TASK_SOFTWARE_FIRST,
    data,
});

export const addTaskActions = (data): LayoutAction => ({
    type: SoftwareActionTypes.ADD_TASK_SOFTWARE_FIRST,
    data,
});

export const addSoftwareOnScanningActions = (data): LayoutAction => ({
    type: SoftwareActionTypes.ADD_SOFTWARE_FOR_SCANNING_FIRST,
    data,
});
// export const getRepeatActions = (data): LayoutAction => ({
//     type: SoftwareActionTypes.GET_REPEAT_FIRST,
//     data,
// });

// REPEAT
export const getScheduleRepeatActions = (): LayoutAction => ({
    type: SoftwareActionTypes.GET_REPEAT_SCHEDULE_FIRST,
});

export const getSoftwareNameActions = (): LayoutAction => ({
    type: SoftwareActionTypes.GET_SOFTWARE_NAME_FIRST,
});

export const addScheduleScanActions = (data): LayoutAction => {
    return {
        // Add this line
        type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_FIRST,
        data,
    };
};

export const statusSoftwareActions = (data): LayoutAction => {
    return {
        // Add this line
        type: SoftwareActionTypes.STATUS_SOFTWARE_FIRST,
        data,
    };
}
// export const addScheduleScanActions = (data): LayoutAction => ({
//     type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_FIRST,
//     data,
// });

export const getUserMailActions = (data): LayoutAction => ({
    type: SoftwareActionTypes.GET_USER_MAIL_FIRST,
    data,
});

// EDIT sCAN
export const editScanScheduleActions = (data): LayoutAction => {
    return {
        type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_FIRST,
        data,
    };
};

export const stateEmptyReschedule = () => ({
    type: SoftwareActionTypes.EMPTY_STATE_RESCHEDULE_FIRST,
});

// new scan

export const getScanActions = (data): LayoutAction => ({
    type: SoftwareActionTypes.GET_SCAN_SCHEDULE_FIRST,
    data,
});

export const getPreviousScanActions = (data): LayoutAction => ({
    type: SoftwareActionTypes.GET_PREVIOUS_SCAN_FIRST,
    data,
});

export const getPreviousAssetActions = (data): LayoutAction => ({
    type: SoftwareActionTypes.GET_PREVIOUS_ASSET_FIRST,
    data,
});

export const deleteSoftwareActions = (id): LayoutAction => ({
    type: SoftwareActionTypes.DELETE_SOFTWARE_FIRST,
    id,
});

export const getUserActions = (data): LayoutAction => ({
    type: SoftwareActionTypes.GET_USER_FIRST,
    data,
});