import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminGetDataMngmntEndPoint(params: any): any {
    const { limit, pages, search, cmId } = params?.data;
    return api.get(`${URL.DATA_MNGMNT_GET}limit=${limit}&page=${pages}&search=${search}&id=${cmId}`);
}

function adminNdSiteAdminAddDataMngmntEndPoint(params: any): any {
    const DataMngmntData = params?.data;
    return api.create(URL.DATA_MNGMNT_ADD, DataMngmntData);
}

function adminNdSiteAdminEditDataMngmntEndPoint(params: any): any {
    const DataMngmntData = params?.data;
    return api.update(URL.DATA_MNGMNT_EDIT, DataMngmntData);
}

function adminNdSiteAdminCsvDataMngmntEndPoint(params: any): any {
    const DataMngmntData = params?.data;
    return api.create(URL.DATA_MNGMNT_CSV, DataMngmntData);
}

function adminNdSiteAdminStatusDataMngmntEndPoint(params: any): any {
    const DataMngmntData = params?.data;
    return api.create(URL.DATA_MNGMNT_STATUS, DataMngmntData);
}

function adminNdSiteAdminAssignGroupsDataMngmntEndPoint(params: any): any {
    const { limit, page, search, cmId } = params?.data;
    return api.get(`${URL.DATA_MNGMNT_ASSIGN_GROUPS}limit=${limit}&page=${page}&search=${search}&id=${cmId}`);
}

function adminNdSiteAdminGetGroupsDataMngmntEndPoint(params: any): any {
    const { id, search, page, limit } = params?.id;
    return api.get(`${URL.GROUPS_LIST_GET}id=${id}&search=${search}&page=${page}&limit=${limit}`);
}

function adminNdSiteAdminAddGroupsDataMngmntAssetsEndPoint(params: any): any {
    const data = params?.data;
    return api.create(URL.DATA_MNGMNT_ADD_GROUPS_ASSETS, data);
}
export {
    adminNdSiteAdminGetDataMngmntEndPoint,
    adminNdSiteAdminAddDataMngmntEndPoint,
    adminNdSiteAdminEditDataMngmntEndPoint,
    adminNdSiteAdminCsvDataMngmntEndPoint,
    adminNdSiteAdminStatusDataMngmntEndPoint,
    adminNdSiteAdminAssignGroupsDataMngmntEndPoint,
    adminNdSiteAdminGetGroupsDataMngmntEndPoint,
    adminNdSiteAdminAddGroupsDataMngmntAssetsEndPoint,
};
