import { AdminNdSiteAdminAssetsActionTypes } from './constants';
export const getAssetsByCompanyIdAction = (data): LayoutAction => {
    return {
        type: AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        data
    };
};

export const getSoftwareListAction = (data): LayoutAction => {
    return {
        type: AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST,
        data
    };
};

export const addAssetsByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const EditAssetsByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const deleteAssetsAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS,
    data,
});

export const statusAssetsByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const csvAssetsByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const stateEmptydAction = (): LayoutAction => ({
    type: AdminNdSiteAdminAssetsActionTypes.STATE_EMPTY_INTIAL_STATE,

});
