import { DocumentsActionTypes } from "./constant";

const GET_DOCUMENTS_INITIAL_STATE = {
    getDocuments: [],
    loading: false,
};

const ADD_DOCUMENTS_INITIAL_STATE = {
    addDocumentsSiteAdmin: [],
    loading: false,
};
const ADD_DOCUMENTS_STATUS_INITIAL_STATE = {
    addDocumentsStatus: [],
    loading: false,
};

const DELETE_DOCUMENTS_ADMIN_INITIAL_STATE = {
    deleteDocument: [],
    loading: false,
};

const getDocumentsReducer = (state = GET_DOCUMENTS_INITIAL_STATE, action) => {
    switch (action.type) {
        case DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_LOADING:
            return {
                ...state,
                loading: true,
            };
        case DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_SUCCESS:
            return {
                ...state,
                getDocuments: action.payload,
                loading: false,
            };
        case DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

const addDocumentsReducer = (state = ADD_DOCUMENTS_INITIAL_STATE, action) => {
    switch (action.type) {
        case DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_LOADING:
            return {
                ...state,
                loading: true,
            };
        case DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_SUCCESS:
            return {
                ...state,
                addDocumentsSiteAdmin: action.payload,
                loading: false,
            };
        case DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_ERROR:
            return {
                ...state,
                loading: false,
            };
        case DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_RESET:
            return ADD_DOCUMENTS_INITIAL_STATE;
        default:
            return state;
    }
};

const addDocumentsStatusReducer = (state = ADD_DOCUMENTS_STATUS_INITIAL_STATE, action) => {
    switch (action.type) {
        case DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_SUCCESS:
            return {
                ...state,
                addDocumentsStatus: action.payload,
                loading: false,
            };
        case DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_ERROR:
            return {
                ...state,
                loading: false,
            };
        case DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_RESET:
            return ADD_DOCUMENTS_STATUS_INITIAL_STATE;
        default:
            return state;
    }
};

const deleteDocumentsSiteAdminReducer = (state = DELETE_DOCUMENTS_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_LOADING:
            return {
                ...state,
                loading: true,
            };
        case DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_SUCCESS:
            return {
                ...state,
                deleteDocument: action.payload,
                loading: false,
            };
        case DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_ERROR:
            return {
                ...state,
                loading: false,
            };
        case DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_RESET:
            return DELETE_DOCUMENTS_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    getDocumentsReducer,
    addDocumentsReducer,
    addDocumentsStatusReducer,
    deleteDocumentsSiteAdminReducer
};
