import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { IpAddressActionTypes } from './constants';
import {
    getIpAddressEndPoint,
    getIpAddressInfoEndPoint,
    getIpAddressScanEndPoint,
    addIpScanScheduleEndPoint,
    editIpScanScheduleEndPoint,
    getIpAddressScanListEndPoint,
    getPreviousIpAddressScanListEndPoint,
    statusIpAddressEndPoint,
    addIpScanEndPoint,
} from './api';
function* CallGetIpAddress(data) {
    try {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_LOADING,
            payload: {},
        });
        const response = yield call(getIpAddressEndPoint, data);
        // response
        // resposne
        if (response.data.status) {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_ERROR,
            payload: error,
        });
    }
}
function* CallGetIpAddressInfo(id) {
    try {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_INFO_LOADING,
            payload: {},
        });
        const response = yield call(getIpAddressInfoEndPoint, id);
        if (response.data.status) {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_INFO_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_INFO_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_INFO_ERROR,
            payload: error,
        });
    }
}
function* CallGetIpAddressScan(data) {
    try {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LOADING,
            payload: {},
        });
        const response = yield call(getIpAddressScanEndPoint, data);
        if (response.data.status) {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_ERROR,
            payload: error,
        });
    }
}
function* CallGetIpAddressScanList() {
    try {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_LOADING,
            payload: {},
        });
        const response = yield call(getIpAddressScanListEndPoint);
        if (response.data.status) {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_ERROR,
            payload: error,
        });
    }
}

function* CallGetPreviousIpAddressScanList(data) {
    try {
        yield put({
            type: IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_LOADING,
            payload: {},
        });
        const response = yield call(getPreviousIpAddressScanListEndPoint, data);
        if (response.data.status) {
            yield put({
                type: IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_ERROR,
            payload: error,
        });
    }
}
function* callAddIpScanSchedule(data) {
    try {
        yield put({
            type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_LOADING,
            payload: {},
        });
        const response = yield call(addIpScanScheduleEndPoint, data);
        if (response.data.status) {
            if (response.data?.Button !== 'Reschedule') {
                yield put({
                    type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_SUCCESS,
                    payload: { ...response.data },
                });
                yield put({
                    type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_RESET,
                    payload: {},
                });
            } else if (response.data?.Button === 'Reschedule') {
                yield put({
                    type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_SUCCESS,
                    payload: { ...response.data },
                });
            }
        } else {
            yield put({
                type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_ERROR,
            payload: error,
        });
    }
}

function* callIponScam(data) {
    try {
        yield put({
            type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_LOADING,
            payload: {},
        });
        const response = yield call(addIpScanEndPoint, data);
        if (response.data.status) {
            yield put({
                type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.ADD_TASK_IP_ERROR,
            payload: error,
        });
        yield put({
            type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_RESET,
            payload: {},
        });
    }
}

function* callEditIpScanSchedule(data) {
    try {
        yield put({
            type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_LOADING,
            payload: {},
        });
        const response = yield call(editIpScanScheduleEndPoint, data);

        if (response.data.status) {
            yield put({
                type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_RESET,
                payload: {},
            });
            yield put({
                type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_RESET,
                payload: {},
            });
            yield put({
                type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        console.error('Error:', error);

        yield put({
            type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_ERROR,
            payload: error,
        });
        yield put({
            type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_RESET,
            payload: {},
        });
        yield put({
            type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_RESET,
            payload: {},
        });
    }
}
function* callStatusIpAddressAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: IpAddressActionTypes.STATUS_IP_ADDRESS_LOADING,
            payload: {},
        });
        const response = yield call(statusIpAddressEndPoint, data);
        if (response.data.status) {
            yield put({
                type: IpAddressActionTypes.STATUS_IP_ADDRESS_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: IpAddressActionTypes.STATUS_IP_ADDRESS_ERROR,
                payload: {},
            });
        } else {
            yield put({
                type: IpAddressActionTypes.STATUS_IP_ADDRESS_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: IpAddressActionTypes.STATUS_IP_ADDRESS_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: IpAddressActionTypes.STATUS_IP_ADDRESS_ERROR,
            payload: error,
        });
        yield put({
            type: IpAddressActionTypes.STATUS_IP_ADDRESS_RESET,
            payload: {},
        });
    }
}

export function* callRescheduleIpEmpty() {
    try {
        yield put({
            type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_RESET,
            payload: {},
        });
    } catch (error) { }
}
export function* getIpAddressAction(): any {
    yield takeEvery(IpAddressActionTypes.GET_IP_ADDRESS_FIRST, CallGetIpAddress);
}
export function* getIpAddressInfoAction(): any {
    yield takeEvery(IpAddressActionTypes.GET_IP_ADDRESS_INFO_FIRST, CallGetIpAddressInfo);
}
export function* getIpAddressScanActionss(): any {
    yield takeEvery(IpAddressActionTypes.GET_IP_ADDRESS_SCAN_FIRST, CallGetIpAddressScan);
}
export function* getIpAddressScanListActionss(): any {
    yield takeEvery(IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_FIRST, CallGetIpAddressScanList);
}

export function* getPreviousIpAddressScanListActions(): any {
    yield takeEvery(IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_FIRST, CallGetPreviousIpAddressScanList);
}

export function* addIpAddressScanActionss(): any {
    yield takeEvery(IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_FIRST, callAddIpScanSchedule);
}
export function* editIpAddressScanActionss(): any {
    yield takeEvery(IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_FIRST, callEditIpScanSchedule);
}
export function* statusIpAddressScanActionss(): any {
    yield takeEvery(IpAddressActionTypes.STATUS_IP_ADDRESS_FIRST, callStatusIpAddressAdminNdSiteAdmin);
}

export function* emptyRescheduleIpStateActionss(): any {
    yield takeEvery(IpAddressActionTypes.EMPTY_STATE_RESCHEDULE_IP_FIRST, callRescheduleIpEmpty);
}

export function* addIpOnScanningAction(): any {
    yield takeEvery(IpAddressActionTypes.ADD_IP_FOR_SCANNING_FIRST, callIponScam);
}

function* getIpAddressSaga(): any {
    yield all([
        fork(getIpAddressAction),
        fork(getIpAddressInfoAction),
        fork(getIpAddressScanActionss),
        fork(addIpAddressScanActionss),
        fork(editIpAddressScanActionss),
        fork(emptyRescheduleIpStateActionss),
        fork(getIpAddressScanListActionss),
        fork(statusIpAddressScanActionss),
        fork(getPreviousIpAddressScanListActions),
        fork(addIpOnScanningAction),
    ]);
}
export default getIpAddressSaga;
