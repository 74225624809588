import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SoftwareActionTypes } from './constants';
import {
    getSoftareEndPoint,
    getTaskEndPoint,
    addTaskEndPoint,
    getScheduleRepeatEndPoint,
    getSoftwareNameEndpoint,
    addScanScheduleEndPoint,
    getUserMailEndpoint,
    getScanEndPoint,
    getPreviousScan,
    getPreviousAsset,
    deleteSoftwareEndPoint,
    editScanScheduleEndPoint,
    statusSoftwareEndPoint,
    addSoftwareScanEndPoint,
    getUserEndpoint,
} from './api';

// start cash day Report

function* callGetSoftware(data) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_SORTWARE_LOADING,
            payload: {},
        });
        const response = yield call(getSoftareEndPoint, data);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.GET_SORTWARE_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: SoftwareActionTypes.GET_SORTWARE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_SORTWARE_ERROR,
            payload: error,
        });
    }
}

function* callGetTaskSoftware(data) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_TASK_SORTWARE_LOADING,
            payload: {},
        });
        const response = yield call(getTaskEndPoint, data);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.GET_TASK_SORTWARE_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: SoftwareActionTypes.GET_TASK_SORTWARE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_TASK_SORTWARE_ERROR,
            payload: error,
        });
    }
}

function* callAddTaskSoftware(data) {
    try {
        yield put({
            type: SoftwareActionTypes.ADD_TASK_SORTWARE_LOADING,
            payload: {},
        });
        const response = yield call(addTaskEndPoint, data);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.ADD_TASK_SORTWARE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.ADD_TASK_SORTWARE_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: SoftwareActionTypes.ADD_TASK_SORTWARE_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.ADD_TASK_SORTWARE_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.ADD_TASK_SORTWARE_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.ADD_TASK_SORTWARE_RESET,
            payload: {},
        });
    }
}

function* callAddScanSchedule(data) {
    try {
        yield put({
            type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_LOADING,
            payload: {},
        });
        const response = yield call(addScanScheduleEndPoint, data);
        if (response.data.status) {
            if (response.data?.Button !== 'Reschedule') {
                yield put({
                    type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_SUCCESS,
                    payload: { ...response.data },
                });
                yield put({
                    type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_RESET,
                    payload: {},
                });
            } else if (response.data?.Button === 'Reschedule') {
                yield put({
                    type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_SUCCESS,
                    payload: { ...response.data },
                });
            }
        } else {
            yield put({
                type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_ERROR,
                payload: { ...response.data },
            });
            // yield put({
            //     type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_RESET,
            //     payload: {},
            // });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_ERROR,
            payload: error,
        });
        // yield put({
        //     type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_RESET,
        //     payload: {},
        // });
    }
}

function* callAddSoftwareonScam(data) {
    try {
        yield put({
            type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_LOADING,
            payload: {},
        });
        const response = yield call(addSoftwareScanEndPoint, data);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.ADD_TASK_SORTWARE_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_RESET,
            payload: {},
        });
    }
}

// scan code
function* callGetScanSchedule(data) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_SCAN_SCHEDULE_LOADING,
            payload: {},
        });
        const response = yield call(getScanEndPoint, data);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.GET_SCAN_SCHEDULE_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: SoftwareActionTypes.GET_SCAN_SCHEDULE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_SCAN_SCHEDULE_ERROR,
            payload: error,
        });
    }
}

function* callGetPreviousScan(data) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_PREVIOUS_SCAN_LOADING,
            payload: {},
        });
        const response = yield call(getPreviousScan, data);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.GET_PREVIOUS_SCAN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: SoftwareActionTypes.GET_PREVIOUS_SCAN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_PREVIOUS_SCAN_ERROR,
            payload: error,
        });
    }
}

function* callGetPreviousAsset(data) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_PREVIOUS_ASSET_LOADING,
            payload: {},
        });
        const response = yield call(getPreviousAsset, data);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.GET_PREVIOUS_ASSET_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: SoftwareActionTypes.GET_PREVIOUS_ASSET_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_PREVIOUS_ASSET_ERROR,
            payload: error,
        });
    }
}

function* callGetRepeatSchedule() {
    try {
        yield put({
            type: SoftwareActionTypes.GET_REPEAT_SCHEDULE_LOADING,
            payload: {},
        });
        const response = yield call(getScheduleRepeatEndPoint);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.GET_REPEAT_SCHEDULE_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: SoftwareActionTypes.GET_REPEAT_SCHEDULE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_REPEAT_SCHEDULE_ERROR,
            payload: error,
        });
    }
}

// function* callGetRepeatSchedule(data) {
//     try {
//         yield put({
//             type: SoftwareActionTypes.GET_REPEAT_SCHEDULE_LOADING,
//             payload: {},
//         });
//         const response = yield call(getScheduleRepeatEndPoint, data);
//         if (response.data.status) {
//             yield put({
//                 type: SoftwareActionTypes.SS,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: SoftwareActionTypes.,
//                 payload: {},
//             });
//         } else {
//             yield put({
//                 type: SoftwareActionTypes.,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: SoftwareActionTypes.,
//                 payload: {},
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: SoftwareActionTypes.,
//             payload: error,
//         });
//         yield put({
//             type: SoftwareActionTypes.,
//             payload: {},
//         });
//     }
// }

// function* callGetSoftwareName() {
//     try {
//         yield put({
//             type: SoftwareActionTypes.GET_SOFTWARE_NAME_LOADING,
//             payload: {},
//         });
//         const response = yield call(getSoftwareNameEndpoint);
//         if (response.data.status) {
//             yield put({
//                 type: SoftwareActionTypes.GET_SOFTWARE_NAME_SUCCESS,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: SoftwareActionTypes.GET_SOFTWARE_NAME_RESET,
//                 payload: {},
//             });
//         } else {
//             yield put({
//                 type: SoftwareActionTypes.GET_SOFTWARE_NAME_ERROR,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: SoftwareActionTypes.GET_SOFTWARE_NAME_RESET,
//                 payload: {},
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: SoftwareActionTypes.GET_SOFTWARE_NAME_ERROR,
//             payload: error,
//         });
//         yield put({
//             type: SoftwareActionTypes.GET_SOFTWARE_NAME_RESET,
//             payload: {},
//         });
//     }
// }

function* callGetSoftwareName() {
    try {
        yield put({
            type: SoftwareActionTypes.GET_SOFTWARE_NAME_LOADING,
            payload: {},
        });
        const response = yield call(getSoftwareNameEndpoint);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.GET_SOFTWARE_NAME_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: SoftwareActionTypes.GET_SOFTWARE_NAME_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_SOFTWARE_NAME_ERROR,
            payload: error,
        });
    }
}

function* callGetUserMail() {
    try {
        yield put({
            type: SoftwareActionTypes.GET_USER_MAIL_LOADING,
            payload: {},
        });
        const response = yield call(getUserMailEndpoint);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.GET_USER_MAIL_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.GET_USER_MAIL_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: SoftwareActionTypes.GET_USER_MAIL_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.GET_USER_MAIL_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_USER_MAIL_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.GET_USER_MAIL_RESET,
            payload: {},
        });
    }
}

// function* callDeleteSoftware(id) {
//     try {
//       yield put({
//         type: SoftwareActionTypes.DELETE_SOFTWARE_LOADING,
//         payload: {},
//       });

//       // Add some debugging logs to check the values

//       const response = yield call(deleteSoftwareEndPoint, id); // Pass the ID directly

//       if (response && response.data && response.data.status) {
//         yield put({
//           type: SoftwareActionTypes.DELETE_SOFTWARE_SUCCESS,
//           payload: { ...response.data },
//         });
//       } else {
//         yield put({
//           type: SoftwareActionTypes.DELETE_SOFTWARE_ERROR,
//           payload: { message: 'Delete operation failed.' }, // Add a custom error message
//         });
//       }
//     } catch (error) {
//       console.error('Error during delete operation:', error);
//       yield put({
//         type: SoftwareActionTypes.DELETE_SOFTWARE_ERROR,
//         payload: { message: 'An error occurred during delete operation.' }, // Add a custom error message
//       });
//     }
//   }

function* callDeleteSoftware(id) {
    try {
        yield put({
            type: SoftwareActionTypes.DELETE_SOFTWARE_LOADING,
            payload: {},
        });
        const response = yield call(deleteSoftwareEndPoint, id);

        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.DELETE_SOFTWARE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.DELETE_SOFTWARE_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: SoftwareActionTypes.DELETE_SOFTWARE_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.DELETE_SOFTWARE_RESET,
                payload: {},
            });
        }
    } catch (error) {
        console.error('Error:', error);

        yield put({
            type: SoftwareActionTypes.DELETE_SOFTWARE_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.DELETE_SOFTWARE_RESET,
            payload: {},
        });
    }
}
// edit scan
function* callEditScanSchedule(data) {
    try {
        yield put({
            type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_LOADING,
            payload: {},
        });
        const response = yield call(editScanScheduleEndPoint, data);

        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_RESET,
                payload: {},
            });
            yield put({
                type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_RESET,
                payload: {},
            });
            yield put({
                type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        console.error('Error:', error);

        yield put({
            type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.EDIT_SCHEDULE_SCAN_RESET,
            payload: {},
        });
        yield put({
            type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_RESET,
            payload: {},
        });
    }
}

export function* callRescheduleEmpty() {
    try {
        yield put({
            type: SoftwareActionTypes.ADD_SCHEDULE_SCAN_RESET,
            payload: {},
        });
    } catch (error) { }
}

function* callStatusSoftwareAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: SoftwareActionTypes.STATUS_SOFTWARE_LOADING,
            payload: {},
        });
        const response = yield call(statusSoftwareEndPoint, data);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.STATUS_SOFTWARE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.STATUS_SOFTWARE_ERROR,
                payload: {},
            });
        } else {
            yield put({
                type: SoftwareActionTypes.STATUS_SOFTWARE_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.STATUS_SOFTWARE_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.STATUS_SOFTWARE_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.STATUS_SOFTWARE_RESET,
            payload: {},
        });
    }
}



function* callGetUser(id) {
    try {
        yield put({
            type: SoftwareActionTypes.GET_USER_LOADING,
            payload: {},
        });
        const response = yield call(getUserEndpoint, id);
        if (response.data.status) {
            yield put({
                type: SoftwareActionTypes.GET_USER_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.GET_USER_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: SoftwareActionTypes.GET_USER_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: SoftwareActionTypes.GET_USER_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: SoftwareActionTypes.GET_USER_ERROR,
            payload: error,
        });
        yield put({
            type: SoftwareActionTypes.GET_USER_RESET,
            payload: {},
        });
    }
}

// end cash day Report List

export function* getSoftwareAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_SOFTWARE_FIRST, callGetSoftware);
}

export function* getTaskSoftwareAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_TASK_SOFTWARE_FIRST, callGetTaskSoftware);
}

export function* addTaskSoftwareAction(): any {
    yield takeEvery(SoftwareActionTypes.ADD_TASK_SOFTWARE_FIRST, callAddTaskSoftware);
}
export function* addSoftwareOnScanningAction(): any {
    yield takeEvery(SoftwareActionTypes.ADD_SOFTWARE_FOR_SCANNING_FIRST, callAddSoftwareonScam);
}
// export function* getRepeatAction(): any {
//     yield takeEvery(SoftwareActionTypes.GET_REPEAT_FIRST, callGetRepeat);
// }

export function* getScheduleRepeatAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_REPEAT_SCHEDULE_FIRST, callGetRepeatSchedule);
}

export function* getSoftwareNameAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_SOFTWARE_NAME_FIRST, callGetSoftwareName);
}

export function* getUserMailAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_USER_MAIL_FIRST, callGetUserMail);
    yield takeEvery(SoftwareActionTypes.GET_USER_FIRST, callGetUser);
}
export function* deleteSoftwareAction(): any {
    yield takeEvery(SoftwareActionTypes.DELETE_SOFTWARE_FIRST, callDeleteSoftware);
}

export function* addScanScheduleeAction(): any {
    yield takeEvery(SoftwareActionTypes.ADD_SCHEDULE_SCAN_FIRST, callAddScanSchedule);
}

// scan code
export function* getScanAction(): any {
    yield takeEvery(SoftwareActionTypes.GET_SCAN_SCHEDULE_FIRST, callGetScanSchedule);
    yield takeEvery(SoftwareActionTypes.GET_PREVIOUS_SCAN_FIRST, callGetPreviousScan);
    yield takeEvery(SoftwareActionTypes.GET_PREVIOUS_ASSET_FIRST, callGetPreviousAsset);
}
// edit scan
export function* EditScanScheduleAction(): any {
    yield takeEvery(SoftwareActionTypes.EDIT_SCHEDULE_SCAN_FIRST, callEditScanSchedule);
}

export function* emptyRescheduleStateActionss(): any {
    yield takeEvery(SoftwareActionTypes.EMPTY_STATE_RESCHEDULE_FIRST, callRescheduleEmpty);
}
export function* statusSoftwareStateActionss(): any {
    yield takeEvery(SoftwareActionTypes.STATUS_SOFTWARE_FIRST, callStatusSoftwareAdminNdSiteAdmin);
}

function* getSoftwareSaga(): any {
    yield all([
        fork(getSoftwareAction),
        fork(getTaskSoftwareAction),
        fork(addTaskSoftwareAction),
        fork(getScheduleRepeatAction),
        fork(getSoftwareNameAction),
        fork(addScanScheduleeAction),
        fork(getUserMailAction),
        fork(deleteSoftwareAction),
        fork(getScanAction),
        fork(EditScanScheduleAction),
        fork(emptyRescheduleStateActionss),
        fork(statusSoftwareStateActionss),
        fork(addSoftwareOnScanningAction),
    ]);
}

export default getSoftwareSaga;
