import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { CreatAdminActionTypes } from './constants';
import { createAdminApiEndPoint, getAdminApiEndPoint, updateAdminApiEndPoint } from './api';
import { AdminNdSiteAdminAssetsActionTypes } from '../assets/constants';

// start cash day Report

function* callCreateAdmin(data) {
    try {
        yield put({
            type: CreatAdminActionTypes.CREATE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(createAdminApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: CreatAdminActionTypes.CREATE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: CreatAdminActionTypes.CREATE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: CreatAdminActionTypes.CREATE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: CreatAdminActionTypes.CREATE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callUpdateAdmin(data) {
    try {
        yield put({
            type: CreatAdminActionTypes.UPDATE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(updateAdminApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: CreatAdminActionTypes.UPDATE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });

        } else {
            yield put({
                type: CreatAdminActionTypes.UPDATE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: CreatAdminActionTypes.UPDATE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callGetAdmin(data) {
    try {
        yield put({
            type: CreatAdminActionTypes.GET_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(getAdminApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: CreatAdminActionTypes.GET_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: CreatAdminActionTypes.GET_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: CreatAdminActionTypes.GET_ADMIN_ERROR,
            payload: error,
        });
    }
}

// end cash day Report List
function* callStateEmpty() {
    put({
        type: CreatAdminActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });

}

export function* stateEmptyAction(): any {
    yield takeEvery(
        CreatAdminActionTypes.STATE_EMPTY_INTIAL_STATE,
        callStateEmpty
    );
}

export function* ActionCreateAdmin(): any {
    yield takeEvery(CreatAdminActionTypes.CREATE_ADMIN_FIRST, callCreateAdmin);
}

export function* ActionGetAdmin(): any {
    yield takeEvery(CreatAdminActionTypes.GET_ADMIN_FIRST, callGetAdmin);
}

export function* ActionUpdateAdmin(): any {
    yield takeEvery(CreatAdminActionTypes.UPDATE_ADMIN_FIRST, callUpdateAdmin);
}

function* AdminSaga(): any {
    yield all([fork(ActionCreateAdmin), fork(ActionGetAdmin), fork(ActionUpdateAdmin),fork(stateEmptyAction)]);
}

export default AdminSaga;
