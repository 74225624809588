import { UsersAddDevicesActionTypes } from "./constants";

const GET_ADD_DEVICES_BY_SELECT_ASSETS_INITIAL_STATE = {
    getAddDeivesBySelectAssets: [],
    loading: false
}
const POST_ADD_DEVICES_BY_SELECT_ASSETS_INITIAL_STATE = {
    postAddDeivesBySelectAssets: [],
    loading: false
}

const addDevicesGetBySelectAssetsReducer = (state = GET_ADD_DEVICES_BY_SELECT_ASSETS_INITIAL_STATE, action) => {
    switch (action.type) {
        case UsersAddDevicesActionTypes.GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_LOADING:
            return {
                getAddDeivesBySelectAssets: state.getAddDeivesBySelectAssets,
                loading: true
            }
        case UsersAddDevicesActionTypes.GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_SUCCESS:
            return {
                getAddDeivesBySelectAssets: action.payload,
                loading: false
            }
        case UsersAddDevicesActionTypes.GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_ERROR:
            return {
                getAddDeivesBySelectAssets: action.payload,
                loading: false
            }
        default: return state
    }

}

const addDevicesPostBySelectAssetsReducer = (state = POST_ADD_DEVICES_BY_SELECT_ASSETS_INITIAL_STATE, action) => {
    switch (action.type) {
        case UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_LOADING:
            return {
                postAddDeivesBySelectAssets: state.postAddDeivesBySelectAssets,
                loading: true
            }
        case UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_SUCCESS:
            return {
                postAddDeivesBySelectAssets: action.payload,
                loading: false
            }
        case UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_ERROR:
            return {
                postAddDeivesBySelectAssets: action.payload,
                loading: false
            }
        case UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_RESET:
            return POST_ADD_DEVICES_BY_SELECT_ASSETS_INITIAL_STATE
        default: return state
    }

}

export { addDevicesGetBySelectAssetsReducer, addDevicesPostBySelectAssetsReducer }