import { TaskActionTypes } from "./constants";

export const getTaskListActions = (data): LayoutAction => ({
    type: TaskActionTypes.GET_TASK_LIST_FIRST,
    data
});

export const getTaskStatusActions = (data): LayoutAction => ({
    type: TaskActionTypes.GET_TASK_STATUS_FIRST,
    data
});

export const addStartTaskActions = (data): LayoutAction => ({
    type: TaskActionTypes.ADD_START_TASK_FIRST,
    data
});

export const addCommentActions = (data): LayoutAction => ({
    type: TaskActionTypes.ADD_COMMENT_FIRST,
    data
});





