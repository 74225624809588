export const SoftwareActionTypes = {
    GET_SOFTWARE_FIRST: '@@/layout/GET_SOFTWARE_ADMIN_ND_SITE_ADMIN',
    GET_SORTWARE_LOADING: '@@/layout/GET_SORTWARE_LOADING',
    GET_SORTWARE_SUCCESS: '@@/layout/GET_SORTWARE_SUCCESS',
    GET_SORTWARE_ERROR: '@@/layout/GET_SORTWARE_ERROR',

    GET_TASK_SOFTWARE_FIRST: '@@/layout/GET_TASK_LOGS_ADMIN_ND_SITE_ADMIN',
    GET_TASK_SORTWARE_LOADING: '@@/layout/GET_TASK_SORTWARE_LOADING',
    GET_TASK_SORTWARE_SUCCESS: '@@/layout/GET_TASK_SORTWARE_SUCCESS',
    GET_TASK_SORTWARE_ERROR: '@@/layout/GET_TASK_SORTWARE_ERROR',

    ADD_TASK_SOFTWARE_FIRST: '@@/layout/ADD_TASK_LOGS_ADMIN_ND_SITE_ADMIN',
    ADD_TASK_SORTWARE_LOADING: '@@/layout/ADD_TASK_SORTWARE_LOADING',
    ADD_TASK_SORTWARE_SUCCESS: '@@/layout/ADD_TASK_SORTWARE_SUCCESS',
    ADD_TASK_SORTWARE_ERROR: '@@/layout/ADD_TASK_SORTWARE_ERROR',
    ADD_TASK_SORTWARE_RESET: '@@/layout/ADD_TASK_SORTWARE_RESET',

    STATUS_SOFTWARE_FIRST: '@@/layout/STATUS_SOFTWARE_FIRST',
    STATUS_SOFTWARE_LOADING: '@@/layout/STATUS_SOFTWARE_LOADING',
    STATUS_SOFTWARE_SUCCESS: '@@/layout/STATUS_SOFTWARE_SUCCESS',
    STATUS_SOFTWARE_ERROR: '@@/layout/STATUS_SOFTWARE_ERROR',
    STATUS_SOFTWARE_RESET: '@@/layout/STATUS_SOFTWARE_RESET',

    // GET_REPEAT_FIRST: '@@/layout/GET_REPEAT_LOGS_ADMIN_ND_SITE_ADMIN',
    // GET_REPEAT_LOADING: '@@/layout/GET_REPEAT_LOADING',
    // GET_REPEAT_SUCCESS: '@@/layout/GET_REPEAT_SUCCESS',
    // GET_REPEAT_ERROR: '@@/layout/GET_REPEAT_ERROR',
    // GET_REPEAT_RESET: '@@/layout/GET_REPEAT_RESET',

    GET_REPEAT_SCHEDULE_FIRST: '@@/layout/GET_REPEAT_SCHEDULE_FIRST',
    GET_REPEAT_SCHEDULE_LOADING: '@@/layout/GET_REPEAT_SCHEDULE_LOADING',
    GET_REPEAT_SCHEDULE_SUCCESS: '@@/layout/GET_REPEAT_SCHEDULE_SUCCESS',
    GET_REPEAT_SCHEDULE_ERROR: '@@/layout/GET_SCAN_SCHEDULE_ERROR',

    // GET_SCAN_SCHEDULE_FIRST

    GET_SCAN_SCHEDULE_FIRST: '@@/layout/GET_SCAN_SCHEDULE_FIRST',
    GET_SCAN_SCHEDULE_LOADING: '@@/layout/GET_SCAN_SCHEDULE_LOADING',
    GET_SCAN_SCHEDULE_SUCCESS: '@@/layout/GET_SCAN_SCHEDULE_SUCCESS',
    GET_SCAN_SCHEDULE_ERROR: '@@/layout/GET_SCAN_SCHEDULE_ERROR',

    GET_PREVIOUS_SCAN_FIRST: '@@/layout/GET_PREVIOUS_SCAN_FIRST',
    GET_PREVIOUS_SCAN_LOADING: '@@/layout/GET_PREVIOUS_SCAN_LOADING',
    GET_PREVIOUS_SCAN_SUCCESS: '@@/layout/GET_PREVIOUS_SCAN_SUCCESS',
    GET_PREVIOUS_SCAN_ERROR: '@@/layout/GET_PREVIOUS_SCAN_ERROR',

    GET_PREVIOUS_ASSET_FIRST: '@@/layout/GET_PREVIOUS_ASSET_FIRST',
    GET_PREVIOUS_ASSET_LOADING: '@@/layout/GET_PREVIOUS_ASSET_LOADING',
    GET_PREVIOUS_ASSET_SUCCESS: '@@/layout/GET_PREVIOUS_ASSET_SUCCESS',
    GET_PREVIOUS_ASSET_ERROR: '@@/layout/GET_PREVIOUS_ASSET_ERROR',

    GET_SOFTWARE_NAME_FIRST: '@@/layout/GET_SOFTWARE_NAME_FIRST',
    GET_SOFTWARE_NAME_LOADING: '@@/layout/GET_SOFTWARE_NAME_LOADING',
    GET_SOFTWARE_NAME_SUCCESS: '@@/layout/GET_SOFTWARE_NAME_SUCCESS',
    GET_SOFTWARE_NAME_ERROR: '@@/layout/GET_SOFTWARE_NAME_ERROR',
    GET_SOFTWARE_NAME_RESET: '@@/layout/GET_SOFTWARE_NAME_RESET',

    ADD_SCHEDULE_SCAN_FIRST: '@@/layout/ADD_SCHEDULE_SCAN_FIRST',
    ADD_SCHEDULE_SCAN_LOADING: '@@/layout/ADD_SCHEDULE_SCAN_LOADING',
    ADD_SCHEDULE_SCAN_SUCCESS: '@@/layout/ADD_SCHEDULE_SCAN_SUCCESS',
    ADD_SCHEDULE_SCAN_ERROR: '@@/layout/ADD_SCHEDULE_SCAN_ERROR',
    ADD_SCHEDULE_SCAN_RESET: '@@/layout/ADD_SCHEDULE_SCAN_RESET',

    EDIT_SCHEDULE_SCAN_FIRST: '@@/layout/EDIT_SCHEDULE_SCAN_FIRST',
    EDIT_SCHEDULE_SCAN_LOADING: '@@/layout/EDIT_SCHEDULE_SCAN_LOADING',
    EDIT_SCHEDULE_SCAN_SUCCESS: '@@/layout/EDIT_SCHEDULE_SCAN_SUCCESS',
    EDIT_SCHEDULE_SCAN_ERROR: '@@/layout/EDIT_SCHEDULE_SCAN_ERROR',
    EDIT_SCHEDULE_SCAN_RESET: '@@/layout/EDIT_SCHEDULE_SCAN_RESET',

    GET_USER_MAIL_FIRST: '@@/layout/GET_USER_MAIL_FIRST',
    GET_USER_MAIL_LOADING: '@@/layout/GET_USER_MAIL_LOADING',
    GET_USER_MAIL_SUCCESS: '@@/layout/GET_USER_MAIL_SUCCESS',
    GET_USER_MAIL_ERROR: '@@/layout/GET_USER_MAIL_ERROR',
    GET_USER_MAIL_RESET: '@@/layout/GET_USER_MAIL_RESET',

    DELETE_SOFTWARE_FIRST: '@@/layout/DELETE_SOFTWARE_FIRST',
    DELETE_SOFTWARE_LOADING: '@@/layout/DELETE_SOFTWARE_LOADING',
    DELETE_SOFTWARE_SUCCESS: '@@/layout/DELETE_SOFTWARE_SUCCESS',
    DELETE_SOFTWARE_ERROR: '@@/layout/DELETE_SOFTWARE_ERROR',
    DELETE_SOFTWARE_RESET: '@@/layout/DELETE_SOFTWARE_RESET',

    EMPTY_STATE_RESCHEDULE_FIRST: '@@/layout/EMPTY_STATE_RESCHEDULE_FIRST',

    ADD_SOFTWARE_FOR_SCANNING_FIRST: '@@/layout/ADD_SOFTWARE_FOR_SCANNING_FIRST',
    ADD_SORTWARE_FOR_SCANNING_LOADING: '@@/layout/ADD_SORTWARE_FOR_SCANNING_LOADING',
    ADD_SORTWARE_FOR_SCANNING_SUCCESS: '@@/layout/ADD_SORTWARE_FOR_SCANNING_SUCCESS',
    ADD_SORTWARE_FOR_SCANNING_ERROR: '@@/layout/ADD_SORTWARE_FOR_SCANNING_ERROR',
    ADD_SORTWARE_FOR_SCANNING_RESET: '@@/layout/ADD_SORTWARE_FOR_SCANNING_RESET',


    GET_USER_FIRST: '@@/layout/GET_USER_FIRST',
    GET_USER_LOADING: '@@/layout/GET_USER_LOADING',
    GET_USER_SUCCESS: '@@/layout/GET_USER_SUCCESS',
    GET_USER_ERROR: '@@/layout/GET_USER_ERROR',
    GET_USER_RESET: '@@/layout/GET_USER_RESET',
};
