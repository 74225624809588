export const AdminNdSiteAdminAddSoftwareActionTypes = {
    POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_FIRST: '@@/layout/POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_FIRST',
    POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_LOADING',
    POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_SUCCESS',
    POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR',
    POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_RESET',

    GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_FIRST: '@@/layout/GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_FIRST',
    GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR',

    GET_IT_DASHBOARD_FIRST: '@@/layout/GET_IT_DASHBOARD_FIRST',
    GET_IT_DASHBOARD_LOADING: '@@/layout/GET_IT_DASHBOARD_LOADING',
    GET_IT_DASHBOARD_SUCCESS: '@@/layout/GET_IT_DASHBOARD_SUCCESS',
    GET_IT_DASHBOARD_ERROR: '@@/layout/GET_IT_DASHBOARD_ERROR',
    GET_IT_DASHBOARD_RESET: '@@/layout/GET_IT_DASHBOARD_RESET',

    DEFAULT_SET_IT_DASHBOARD_FIRST: '@@/layout/GET_IT_DASHBOARD_FIRST',
    DEFAULT_SET_IT_DASHBOARD_SUCCESS: '@@/layout/DEFAULT_SET_DASHBOARD_SUCCESS',

    // ip address
    POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_FIRST: '@@/layout/POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_FIRST',
    POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_LOADING',
    POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_SUCCESS',
    POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_ERROR',
    POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_RESET',

    // Get For Total Assests and Software
    GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_FIRST: '@@/layout/GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_FIRST',
    GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_LOADING: '@@/layout/GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_LOADING',
    GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_SUCCESS: '@@/layout/GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_SUCCESS',
    GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_ERROR: '@@/layout/GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_ERROR',
    GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_RESET: '@@/layout/GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_RESET',

    GET_SUPER_ADMIN_DASHBOARD_FIRST: '@@/layout/GET_SUPER_ADMIN_DASHBOARD_FIRST',
    GET_SUPER_ADMIN_DASHBOARD_LOADING: '@@/layout/GET_SUPER_ADMIN_DASHBOARD_LOADING',
    GET_SUPER_ADMIN_DASHBOARD_SUCCESS: '@@/layout/GET_SUPER_ADMIN_DASHBOARD_SUCCESS',
    GET_SUPER_ADMIN_DASHBOARD_ERROR: '@@/layout/GET_SUPER_ADMIN_DASHBOARD_ERROR',
    GET_SUPER_ADMIN_DASHBOARD_RESET: '@@/layout/GET_SUPER_ADMIN_DASHBOARD_RESET',

    // Get ALL Instance Withvulnerability And Without
    POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_FIRST: '@@/layout/POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_FIRST',
    POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_LOADING: '@@/layout/POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_LOADING',
    POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_SUCCESS: '@@/layout/POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_SUCCESS',
    POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_ERROR: '@@/layout/POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_ERROR',
    POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_RESET: '@@/layout/POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_RESET',
  

};
