import { UserMyDataSourceActionTypes } from "./constants";

const GET_MY_DATA_SOURCE_BY_GROUPS_INITIAL_STATE = {
    getMyDataSourceByGroups: [],
    loading: false
}


const getMyDataSourceByGroupsReducer = (state = GET_MY_DATA_SOURCE_BY_GROUPS_INITIAL_STATE, action) => {
    switch (action.type) {
        case UserMyDataSourceActionTypes.GET_MY_DATA_SOURCES_GET_BY_GROUPS_USER_LOADING:
            return {
                getMyDataSourceByGroups: state.getMyDataSourceByGroups,
                loading: true
            }
        case UserMyDataSourceActionTypes.GET_MY_DATA_SOURCES_GET_BY_GROUPS_USER_SUCCESS:
            return {
                getMyDataSourceByGroups: action.payload,
                loading: false
            }
        case UserMyDataSourceActionTypes.GET_MY_DATA_SOURCES_GET_BY_GROUPS_USER_ERROR:
            return {
                getMyDataSourceByGroups: action.payload,
                loading: false
            }
        default: return state
    }

}


export { getMyDataSourceByGroupsReducer }