import { DocumentsActionTypes } from "./constant";

export const getDocumenetsSiteAdminActions = (data): LayoutAction => ({
    type: DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN,
    data,
});
export const addDocumentsSiteAdminActions = (data): LayoutAction => ({
    type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN,
    data,
});

export const addDocumentsStatusActions = (data): LayoutAction => ({
    type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS,
    data,
});

export const deleteDocumentSiteAdminActions = (id): LayoutAction => ({
    type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN,
    id,
});
