export const ProfileUpdateActionTypes = {
    PROFILE_UPDATE_FIRST: '@@/layout/PROFILE_UPDATE_FIRST',
    PROFILE_UPDATE_LOADING: '@@/layout/PROFILE_UPDATE_LOADING',
    PROFILE_UPDATE_SUCCESS: '@@/layout/PROFILE_UPDATE_SUCCESS',
    PROFILE_UPDATE_ERROR: '@@/layout/PROFILE_UPDATE_ERROR',
    PROFILE_UPDATE_RESET: '@@/layout/PROFILE_UPDATE_RESET',


    FORGET_PASSWORD_UPDATE_FIRST: '@@/layout/FORGET_PASSWORD_UPDATE_FIRST',
    FORGET_PASSWORD_UPDATE_LOADING: '@@/layout/FORGET_PASSWORD_UPDATE_LOADING',
    FORGET_PASSWORD_UPDATE_SUCCESS: '@@/layout/FORGET_PASSWORD_UPDATE_SUCCESS',
    FORGET_PASSWORD_UPDATE_ERROR: '@@/layout/FORGET_PASSWORD_UPDATE_ERROR',
    FORGET_PASSWORD_UPDATE_RESET: '@@/layout/FORGET_PASSWORD_UPDATE_RESET',

}