import { APICore } from '../../../helpers/api/apiCore';
import * as URL from "../../../constants/endPoint"

const api = new APICore();

function porfileUpdateEndPoint(params: any): any {
    const upateData = params.data
    return api.create(URL.PROFILE_UPDATE, upateData);
}

function forgetPasswordEndPoint(params: any): any {
    const upateData = params.data
    return api.update(URL.FORGET_PASSWORD, upateData);
}



export { porfileUpdateEndPoint, forgetPasswordEndPoint }