import { AdminNdSiteAdminAccountsActionTypes } from './constants';

const GET_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    getAccountsAdminNdSiteAdmin: [],
    loading: false,
};

const ADD_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    addAccountsAdminNdSiteAdmin: [],
    loading: false,
};

const EDIT_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    editAccountsAdminNdSiteAdmin: [],
    loading: false,
};

const STATUS_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    statusAccountsAdminNdSiteAdmin: [],
    loading: false,
};

const ASSIGN_GROUPS_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    assignGroupsAccountsAdminNdSiteAdmin: [],
    loading: false,
};

const GET_GROUPS_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    getGroupsAccountsAdminNdSiteAdmin: [],
    loading: false,
};

const USER_PASSWORD_RESET_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    userPasswordResetAccountsAdminNdSiteAdmin: [],
    loading: false,
};

const getAccountsAdminNdSiteAdminReducer = (state = GET_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAccountsActionTypes.GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getAccountsAdminNdSiteAdmin: state.getAccountsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAccountsActionTypes.GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE
        default:
            return state;
    }
};

const addAccountsAdminNdSiteAdminReducer = (state = ADD_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                addAccountsAdminNdSiteAdmin: state.addAccountsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                addAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                addAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return ADD_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE
        default:
            return state;
    }
};

const editAccountsAdminNdSiteAdminReducer = (state = EDIT_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                editAccountsAdminNdSiteAdmin: state.editAccountsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                editAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                editAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return EDIT_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const statusAccountsAdminNdSiteAdminReducer = (state = STATUS_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                statusAccountsAdminNdSiteAdmin: state.statusAccountsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                statusAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                statusAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return STATUS_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const assignGroupsAccountsAdminNdSiteAdminReducer = (state = ASSIGN_GROUPS_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                assignGroupsAccountsAdminNdSiteAdmin: state.assignGroupsAccountsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                assignGroupsAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                assignGroupsAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return ASSIGN_GROUPS_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const getGroupsAccountsAdminNdSiteAdminReducer = (state = GET_GROUPS_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getGroupsAccountsAdminNdSiteAdmin: state.getGroupsAccountsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getGroupsAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getGroupsAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return GET_GROUPS_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        case AdminNdSiteAdminAccountsActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_GROUPS_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const userPasswordResetAccountsAdminNdSiteAdminReducer = (state = USER_PASSWORD_RESET_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                userPasswordResetAccountsAdminNdSiteAdmin: state.userPasswordResetAccountsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                userPasswordResetAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                userPasswordResetAccountsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return USER_PASSWORD_RESET_ACCOUNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    getAccountsAdminNdSiteAdminReducer,
    addAccountsAdminNdSiteAdminReducer,
    editAccountsAdminNdSiteAdminReducer,
    statusAccountsAdminNdSiteAdminReducer,
    assignGroupsAccountsAdminNdSiteAdminReducer,
    getGroupsAccountsAdminNdSiteAdminReducer,
    userPasswordResetAccountsAdminNdSiteAdminReducer,

};
