import { APICore } from '../../../../helpers/api/apiCore';
import * as URL from "../../../../constants/endPoint"

const api = new APICore();

function userAddDevicesGetAssetsEndPoint(params: any): any {
    const { search, page, limit } = params.payload
    return api.get(`${URL.ADD_DEVICES_GET}search=${search}&page=${page}&limit=${limit}`);
}

function userAddDevicesPostAssetsEndPoint(params: any): any {
    const selectData = params?.payload
    return api.create(URL.ADD_DEVICES_POST, selectData);
}

export { userAddDevicesGetAssetsEndPoint, userAddDevicesPostAssetsEndPoint }