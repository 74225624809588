import { EmailVerifyActionTypes } from './constants';

const EMAIL_VERIFY_INITIAL_STATE = {
    emailVerify: [],
    loading: false,
};
const RESET_CONFIRM_PASSWORD_INITIAL_STATE = {
    resetCofirmPassword: [],
    loading: false,
};

const emailVerifyReducer = (state = EMAIL_VERIFY_INITIAL_STATE, action) => {
    switch (action.type) {
        case EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_FIRST:
            return {
                emailVerify: state.emailVerify,
                loading: true,
            };
        case EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_SUCCESS:
            return {
                emailVerify: action.payload,
                loading: false,
            };
        case EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_ERROR:
            return {
                emailVerify: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
const confirmResetPasswordReducer = (state = RESET_CONFIRM_PASSWORD_INITIAL_STATE, action) => {
    switch (action.type) {

        case EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_LOADING:
            return {
                resetCofirmPassword: state.resetCofirmPassword,
                loading: true,
            };
        case EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_SUCCESS:
            return {
                resetCofirmPassword: action.payload,
                loading: false,
            };
        case EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_ERROR:
            return {
                resetCofirmPassword: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export { emailVerifyReducer, confirmResetPasswordReducer };
