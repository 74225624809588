import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminAccountsActionTypes } from './constants';
import {
    adminNdSiteAdminAccountsGetApiEndPoint,
    adminNdSiteAdminAccountsAddApiEndPoint,
    adminNdSiteAdminAccountsEditApiEndPoint,
    adminNdSiteAdminAccountsStatusApiEndPoint,
    adminNdSiteAdminAccountsAssignGroupsApiEndPoint,
    adminNdSiteAdminAccountsGetGroupsApiEndPoint,
    adminNdSiteAdminAccountsUserPasswordResetApiEndPoint
} from './api';

// start cash day Report

function* callGetAccountsByCompanyIdAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAccountsGetApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callAddAccountsByCompanyIdAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAccountsAddApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callEditAccountsByCompanyIdAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAccountsEditApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStatusAccountsByCompanyIdAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAccountsStatusApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callAssignGroupsssAccountsByCompanyIdAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAccountsAssignGroupsApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callGetGroupsssAccountsByCompanyIdAdminNdSiteAdmin(id) {
    try {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAccountsGetGroupsApiEndPoint, id);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });

        } else {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callUserPasswordResetAccountsByCompanyIdAdminNdSiteAdmin(id) {
    try {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAccountsUserPasswordResetApiEndPoint, id);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminAccountsActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });

}





// end cash day Report List

export function* getAccountsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAccountsActionTypes.GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callGetAccountsByCompanyIdAdminNdSiteAdmin
    );
}

export function* addAccountsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callAddAccountsByCompanyIdAdminNdSiteAdmin
    );
}

export function* editAccountsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callEditAccountsByCompanyIdAdminNdSiteAdmin
    );
}

export function* statusAccountsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callStatusAccountsByCompanyIdAdminNdSiteAdmin
    );
}

export function* getGroupsAccountsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callGetGroupsssAccountsByCompanyIdAdminNdSiteAdmin
    );
}

export function* assignGroupsAccountsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callAssignGroupsssAccountsByCompanyIdAdminNdSiteAdmin
    );
}

export function* userPasswordResetAccountsByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callUserPasswordResetAccountsByCompanyIdAdminNdSiteAdmin
    );
}

export function* stateEmptyAction(): any {
    yield takeEvery(
        AdminNdSiteAdminAccountsActionTypes.STATE_EMPTY_INTIAL_STATE,
        callStateEmpty
    );
}


function* getAccountsByCompanyIdAdminNdSiteAdminSaga(): any {
    yield all([
        fork(getAccountsByComapnyIdAdminNdSiteAdminAction),
        fork(addAccountsByComapnyIdAdminNdSiteAdminAction),
        fork(editAccountsByComapnyIdAdminNdSiteAdminAction),
        fork(statusAccountsByComapnyIdAdminNdSiteAdminAction),
        fork(assignGroupsAccountsByComapnyIdAdminNdSiteAdminAction),
        fork(getGroupsAccountsByComapnyIdAdminNdSiteAdminAction),
        fork(userPasswordResetAccountsByComapnyIdAdminNdSiteAdminAction),
        fork(stateEmptyAction)
    ]);
}

export default getAccountsByCompanyIdAdminNdSiteAdminSaga;
