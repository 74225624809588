import { AdminNdSiteAdminGroupsActionTypes } from './constants';

const GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    getGroupsListAdminNdSiteAdmin: [],
    loading: false,
};

const ADD_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    addGroupsAdminNdSiteAdmin: [],
    loading: false,
};

const EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    editGroupsAdminNdSiteAdmin: [],
    loading: false,
};

const STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    statusGroupsAdminNdSiteAdmin: [],
    loading: false,
};

const DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    deleteGroupsAdminNdSiteAdmin: [],
    loading: false,
};

const GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    getGroupsMngmntAdminNdSiteAdmin: [],
    loading: false,
};

const GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    getGroupsUsersAdminNdSiteAdmin: [],
    loading: false,
};

const ASSIGN_GROUPS_DELETE_INITIAL_STATE = {
    assignGroupsDelete: "",
    loading: false
}

const getGroupsListAdminNdSiteAdminReducer = (state = GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getGroupsListAdminNdSiteAdmin: state.getGroupsListAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getGroupsListAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getGroupsListAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_INITIAL_STATE
        default:
            return state;
    }
};

const addGroupsAdminNdSiteAdminReducer = (state = ADD_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                addGroupsAdminNdSiteAdmin: state.addGroupsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                addGroupsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                addGroupsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN_RESET:
            return ADD_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE
        default:
            return state;
    }
};

const editGroupsAdminNdSiteAdminReducer = (state = EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                editGroupsAdminNdSiteAdmin: state.editGroupsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                editGroupsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                editGroupsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_RESET:
            return EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE
        default:
            return state;
    }
};

const statusGroupsAdminNdSiteAdminReducer = (state = STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                statusGroupsAdminNdSiteAdmin: state.statusGroupsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                statusGroupsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                statusGroupsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_RESET:
            return STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE
        default:
            return state;
    }
};

const deleteGroupsAdminNdSiteAdminReducer = (state = DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                deleteGroupsAdminNdSiteAdmin: state.deleteGroupsAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                deleteGroupsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                deleteGroupsAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_RESET:
            return DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE
        default:
            return state;
    }
};

const getGroupsMngmntAdminNdSiteAdminReducer = (state = GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getGroupsMngmntAdminNdSiteAdmin: state.getGroupsMngmntAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getGroupsMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getGroupsMngmntAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET:
            return GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE
        case AdminNdSiteAdminGroupsActionTypes.DATA_MNGMNT_EMPTY_SUCCESS:
            return GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE
        default:
            return state;
    }
};

const getGroupsUsersAdminNdSiteAdminReducer = (state = GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getGroupsUsersAdminNdSiteAdmin: state.getGroupsUsersAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getGroupsUsersAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getGroupsUsersAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_RESET:
            return GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE
        case AdminNdSiteAdminGroupsActionTypes.USERS_ACCOUNT_EMTPTY_SUCCESS:
            return GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_INITIAL_STATE
        default:
            return state;
    }
};


const assignGroupsDeleteAdminNdSiteAdminReducer = (state = ASSIGN_GROUPS_DELETE_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                assignGroupsDelete: state.assignGroupsDelete,
                loading: true,
            };
        case AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                assignGroupsDelete: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                assignGroupsDelete: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_RESET:
            return ASSIGN_GROUPS_DELETE_INITIAL_STATE
        default:
            return state;
    }
};

export {
    getGroupsListAdminNdSiteAdminReducer,
    addGroupsAdminNdSiteAdminReducer,
    editGroupsAdminNdSiteAdminReducer,
    statusGroupsAdminNdSiteAdminReducer,
    deleteGroupsAdminNdSiteAdminReducer,
    getGroupsMngmntAdminNdSiteAdminReducer,
    getGroupsUsersAdminNdSiteAdminReducer,
    assignGroupsDeleteAdminNdSiteAdminReducer
};
