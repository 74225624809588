import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function getSoftareEndPoint(params: any): any {
    const { companyId, search, limit, page } = params?.data;
    return api.get(`${URL.GET_SOFTARE}companyId=${companyId}&search=${search}&limit=${limit}&page=${page}`);
}

function getTaskEndPoint(params: any): any {
    const { companyId } = params?.data;
    return api.get(`${URL.GET_TASK}${companyId}`);
}

function addTaskEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.ADD_TASK, data);
}
function addScanScheduleEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.ADD_SOFTWARE_SCAN, data);
}

function addSoftwareScanEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.ADD_SOFTWARE_ON_SCANNING, data);
}

function getScheduleRepeatEndPoint(params: any): any {
    return api.get(URL.GET_REPEAT_SCHEDULE);
}

function getSoftwareNameEndpoint(params: any): any {
    return api.get(URL.GET_SOFTWARE_NAME);
}

function getUserMailEndpoint(params: any): any {
    return api.get(URL.GET_USER_MAIL);
}
function getScanEndPoint(params: any): any {
    const { search, limit, page } = params?.data;
    return api.get(`${URL.GET_SCAN}limit=${limit}&page=${page}&search=${search}`);
}

function getPreviousScan(params: any): any {
    const { companyId, softwareId } = params?.data;
    return api.get(`${URL.GET_PREVIOUS_SCAN}companyId=${companyId}&softwareId=${softwareId}`);
}

function getPreviousAsset(params: any): any {
    const { companyId, softwareId, assetName, assetId } = params?.data;
    return api.get(
        `${URL.GET_PREVIOUS_ASSET}asset_id=${assetId}&assetname=${assetName}&software_id=${softwareId}&company_id=${companyId}`
    );
}

function deleteSoftwareEndPoint(params) {
    const { id } = params || {};
    if (id) {
        return api.create(`${URL.DELETE_SOFTWARE}/${id}`);
    } else {
        throw new Error('Invalid software ID');
    }
}
function editScanScheduleEndPoint(params: any): any {
    const { data } = params;
    return api.update(URL.EDIT_SCAN_SCHEDULE, data);
}
function statusSoftwareEndPoint(params: any): any {
    const { data } = params;
    return api.update(URL.STATUS_SOFTWARE, data);
}

function getUserEndpoint(params: any): any {
    const { id } = params?.data;
    return api.get(`${URL.GET_USER}${id}`);
}

export {
    getSoftareEndPoint,
    getTaskEndPoint,
    addTaskEndPoint,
    getScheduleRepeatEndPoint,
    getSoftwareNameEndpoint,
    getUserMailEndpoint,
    getScanEndPoint,
    getPreviousScan,
    getPreviousAsset,
    addScanScheduleEndPoint,
    deleteSoftwareEndPoint,
    editScanScheduleEndPoint,
    statusSoftwareEndPoint,
    addSoftwareScanEndPoint,
    getUserEndpoint,
};
