import { UsersAddDevicesActionTypes } from "./constants";

export const addDevicesGetByAssetsAction = (data): LayoutAction => ({
    type: UsersAddDevicesActionTypes.GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER,
    payload: data,
});

export const addDevicesPostByAssetsAction = (data): LayoutAction => ({
    type: UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER,
    payload: data,
});

