import { EmailVerifyActionTypes } from './constants';

export const emailVerifyFirst = (id): LayoutAction => ({
    type: EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_FIRST,
    id,
});
 
export const resetPasswordConfirm = (data): LayoutAction => ({
    type: EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_FIRST,
    data,
});