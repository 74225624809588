import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminAccountsGetApiEndPoint(params: any): any {
    const { cmId, limit, page, search } = params?.data;
    return api.get(`${URL.ACCOUNT_GET}id=${cmId}&search=${search}&limit=${limit}&page=${page}`);
}

function adminNdSiteAdminAccountsAddApiEndPoint(params: any): any {
    const accountData = params?.data;
    return api.create(URL.ACCOUNT_ADD, accountData);
}

function adminNdSiteAdminAccountsEditApiEndPoint(params: any): any {
    const accountData = params?.data;
    return api.update(URL.ACCOUNT_EDIT, accountData);
}

function adminNdSiteAdminAccountsStatusApiEndPoint(params: any): any {
    const accountData = params?.data;
    return api.create(URL.ACCOUNT_STATUS, accountData);
}

function adminNdSiteAdminAccountsGetGroupsApiEndPoint(params: any): any {
    const { cmId, search, limit, page } = params?.id;
    return api.get(`${URL.GROUPS_LIST_GET}id=${cmId}&search=${search}&page=${page}&limit=${limit}`);
}

function adminNdSiteAdminAccountsAssignGroupsApiEndPoint(params: any): any {
    const accountData = params?.data;
    return api.create(URL.ACCOUNT_ASSIGN_GROUPS, accountData);
}

function adminNdSiteAdminAccountsUserPasswordResetApiEndPoint(params: any): any {
    const id = params?.id;
    return api.create(URL.ACCOUNT_USER_PASSWORD_RESET, id);
}


export {
    adminNdSiteAdminAccountsGetApiEndPoint,
    adminNdSiteAdminAccountsAddApiEndPoint,
    adminNdSiteAdminAccountsEditApiEndPoint,
    adminNdSiteAdminAccountsStatusApiEndPoint,
    adminNdSiteAdminAccountsGetGroupsApiEndPoint,
    adminNdSiteAdminAccountsAssignGroupsApiEndPoint,
    adminNdSiteAdminAccountsUserPasswordResetApiEndPoint
};
