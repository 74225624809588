import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { DocumentsActionTypes } from './constant';
import {
    AddDocuments,
    AddDocumentsStatus,
    getDocuments,
    deleteDocuments
} from './api';

// start cash day Report

function* callGetDocumentsSiteAdmin({ data }) {
    try {
        yield put({
            type: DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(getDocuments, data);
        if (response.data.status) {
            yield put({
                type: DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callAddDocumenstSiteAdmin(data) {
    try {
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(AddDocuments, data);
        if (response.data.status) {
            yield put({
                type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}
function* callAddDocumenstStatus(data) {
    try {
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_LOADING,
            payload: {},
        });
        const response = yield call(AddDocumentsStatus, data);
        if (response.data.status) {
            yield put({
                type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_ERROR,
            payload: error,
        });
        yield put({
            type: DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS_RESET,
            payload: {},
        });
    }
}

function* calldeleteDocumentNdSiteAdmin(id) {
    try {
        yield put({
            type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(deleteDocuments, id);
        if (response.data.status) {
            yield put({
                type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

export function* getDocumentsSiteAdminAction(): any {
    yield takeEvery(
        DocumentsActionTypes.GET_DOCUMENTS_SITE_ADMIN,
        callGetDocumentsSiteAdmin
    );
}

export function* addDocumentsSiteAdminAction(): any {
    yield takeEvery(
        DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN,
        callAddDocumenstSiteAdmin
    );
}

export function* addDocumentsStatusAction(): any {
    yield takeEvery(
        DocumentsActionTypes.ADD_DOCUMENTS_SITE_ADMIN_STATUS,
        callAddDocumenstStatus
    );
}

export function* deleteDocument(): any {
    yield takeEvery(
        DocumentsActionTypes.DELETE_DOCUMENTS_SITE_ADMIN,
        calldeleteDocumentNdSiteAdmin
    );
}

function* getDocumentsSiteAdminSaga(): any {
    yield all([
        fork(getDocumentsSiteAdminAction),
        fork(addDocumentsSiteAdminAction),
        fork(addDocumentsStatusAction),
        fork(deleteDocument),
    ]);
}

export default getDocumentsSiteAdminSaga;
