import { CreatAdminActionTypes } from './constants';
import { AdminNdSiteAdminAssetsActionTypes } from '../assets/constants';

const CREATE_ADMIN_INITIAL_STATE = {
    createAdmin: [],
    loading: false,
};

const GET_ADMIN_INITIAL_STATE = {
    getAdmin: [],
    loading: false,
};

const UPDATE_ADMIN_INITIAL_STATE = {
    updateAdmin: [],
    loading: false,
};
const createAdminReducer = (state = CREATE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case CreatAdminActionTypes.CREATE_ADMIN_LOADING:
            return {
                createAdmin: state.createAdmin,
                loading: true,
            };
        case CreatAdminActionTypes.CREATE_ADMIN_SUCCESS:
            return {
                createAdmin: action.payload,
                loading: false,
            };
        case CreatAdminActionTypes.CREATE_ADMIN_ERROR:
            return {
                createAdmin: action.payload,
                loading: false,
            };
        case CreatAdminActionTypes.STATE_EMPTY_INTIAL_STATE:
            return CREATE_ADMIN_INITIAL_STATE
        default:
            return state;
    }
};

const getAdminReducer = (state = GET_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case CreatAdminActionTypes.GET_ADMIN_LOADING:
            return {
                getAdmin: state.getAdmin,
                loading: true,
            };
        case CreatAdminActionTypes.GET_ADMIN_SUCCESS:
            return {
                getAdmin: action.payload,
                loading: false,
            };
        case CreatAdminActionTypes.GET_ADMIN_ERROR:
            return {
                getAdmin: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

const updateAdminReducer = (state = UPDATE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case CreatAdminActionTypes.UPDATE_ADMIN_LOADING:
            return {
                updateAdmin: state.updateAdmin,
                loading: true,
            };
        case CreatAdminActionTypes.UPDATE_ADMIN_SUCCESS:
            return {
                updateAdmin: action.payload,
                loading: false,
            };
        case CreatAdminActionTypes.UPDATE_ADMIN_ERROR:
            return {
                updateAdmin: action.payload,
                loading: false,
            };
        case CreatAdminActionTypes.STATE_EMPTY_INTIAL_STATE:
            return UPDATE_ADMIN_INITIAL_STATE

        default:
            return state;
    }
};

export { createAdminReducer, getAdminReducer, updateAdminReducer };
