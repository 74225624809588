import { ProfileUpdateActionTypes } from "./constants";

const PROFILE_UPDATE_INITIAL_STATE = {
    profileUpdate: [],
    loading: false
}

const FORGET_PASSWORD_INITIAL_STATE = {
    forgetPassword: [],
    loading: false
}

const profileUpdateReducer = (state = PROFILE_UPDATE_INITIAL_STATE, action) => {
    switch (action.type) {
        case ProfileUpdateActionTypes.PROFILE_UPDATE_LOADING:
            return {
                profileUpdate: state.profileUpdate,
                loading: true
            }
        case ProfileUpdateActionTypes.PROFILE_UPDATE_SUCCESS:
            return {
                profileUpdate: action.payload,
                loading: false
            }
        case ProfileUpdateActionTypes.PROFILE_UPDATE_ERROR:
            return {
                profileUpdate: action.payload,
                loading: false
            }
            case ProfileUpdateActionTypes.PROFILE_UPDATE_RESET:
                return PROFILE_UPDATE_INITIAL_STATE
        default: return state
    }
}

const forgetPasswordReducer = (state = FORGET_PASSWORD_INITIAL_STATE, action) => {
    switch (action.type) {
        case ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_LOADING:
            return {
                forgetPassword: state.forgetPassword,
                loading: true
            }
        case ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_SUCCESS:
            return {
                forgetPassword: action.payload,
                loading: false
            }
        case ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_ERROR:
            return {
                forgetPassword: action.payload,
                loading: false
            }
        case ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_RESET:
            return FORGET_PASSWORD_INITIAL_STATE
        default: return state
    }
}

export { profileUpdateReducer, forgetPasswordReducer };