import { APICore } from '../../helpers/api/apiCore';
import * as URL from '../../constants/endPoint';

const api = new APICore();

function putForcePasswordApiEndPoint(params: any): any {
    const { data } = params
    return api.update(URL.FORCE_PASSWORD, data);
}

export {
    putForcePasswordApiEndPoint,
};
