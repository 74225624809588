import { IpAddressActionTypes } from './constants';
export const getIpAddressActions = (data) => ({
    type: IpAddressActionTypes.GET_IP_ADDRESS_FIRST,
    data,
});
export const getIpAddressInfoActions = (id): LayoutAction => ({
    type: IpAddressActionTypes.GET_IP_ADDRESS_INFO_FIRST,
    id,
});
export const getIpAddressScan = (data): LayoutAction => ({
    type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_FIRST,
    data,
});

export const getPreviousIpAddressScanList = (data): LayoutAction => ({
    type: IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_FIRST,
    data,
});
export const getIpAddressScanListActons = (): LayoutAction => ({
    type: IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_FIRST,
});
export const statusIpAddressAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: IpAddressActionTypes.STATUS_IP_ADDRESS_FIRST,
    data,
});

export const addScheduleScanIpActions = (data): LayoutAction => {
    return {
        // Add this line
        type: IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_FIRST,
        data,
    };
};
export const editScanScheduleIpActions = (data): LayoutAction => {
    return {
        type: IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_FIRST,
        data,
    };
};
export const stateEmptyRescheduleIp = () => ({
    type: IpAddressActionTypes.EMPTY_STATE_RESCHEDULE_IP_FIRST,
});
export const addIpOnScanningActions = (data): LayoutAction => ({
    type: IpAddressActionTypes.ADD_IP_FOR_SCANNING_FIRST,
    data,
});
