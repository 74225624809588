import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { UsersAddDevicesActionTypes } from './constants';
import { userAddDevicesGetAssetsEndPoint, userAddDevicesPostAssetsEndPoint } from './api';


// start cash day Report

function* callAddDevicesGetBySelectAssets(data) {
    try {
        yield put({
            type: UsersAddDevicesActionTypes.GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_LOADING,
            payload: {},
        });
        const response = yield call(userAddDevicesGetAssetsEndPoint, data);
        if (response.data.status) {
            yield put({
                type: UsersAddDevicesActionTypes.GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: UsersAddDevicesActionTypes.GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: UsersAddDevicesActionTypes.GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_ERROR,
            payload: error,
        });
    }
}

function* callAddDevicesPostBySelectAssets(data) {
    try {
        yield put({
            type: UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_LOADING,
            payload: {},
        });
        const response = yield call(userAddDevicesPostAssetsEndPoint, data);
        if (response.data.status) {
            yield put({
                type: UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_ERROR,
            payload: error,
        });
        yield put({
            type: UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_RESET,
            payload: {},
        });
    }
}
// end cash day Report List


export function* getAddDevicesGetByAssetsAction(): any {
    yield takeEvery(UsersAddDevicesActionTypes.GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER, callAddDevicesGetBySelectAssets);
}

export function* postAddDevicesPostByAssetsAction(): any {
    yield takeEvery(UsersAddDevicesActionTypes.POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER, callAddDevicesPostBySelectAssets);
}

function* getAddDevicesGetByAssetsSaga(): any {
    yield all([
        fork(getAddDevicesGetByAssetsAction), fork(postAddDevicesPostByAssetsAction)
    ]);
}

export default getAddDevicesGetByAssetsSaga;
