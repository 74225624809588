import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { UserMyDataSourceActionTypes } from './constants';
import { userMyDataSourceByGroupsEndPoint, } from './api';


// start cash day Report

function* callGetMyDataSourceByGroups(id) {
    try {
        yield put({
            type: UserMyDataSourceActionTypes.GET_MY_DATA_SOURCES_GET_BY_GROUPS_USER_LOADING,
            payload: {},
        });
        const response = yield call(userMyDataSourceByGroupsEndPoint, id);
        if (response.data.status) {
            yield put({
                type: UserMyDataSourceActionTypes.GET_MY_DATA_SOURCES_GET_BY_GROUPS_USER_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: UserMyDataSourceActionTypes.GET_MY_DATA_SOURCES_GET_BY_GROUPS_USER_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: UserMyDataSourceActionTypes.GET_MY_DATA_SOURCES_GET_BY_GROUPS_USER_ERROR,
            payload: error,
        });
    }
}

// end cash day Report List


export function* getMyDataSourceByGroupsAction(): any {
    yield takeEvery(UserMyDataSourceActionTypes.GET_MY_DATA_SOURCES_GET_BY_GROUPS_USER, callGetMyDataSourceByGroups);
}


function* getMyDataSourceByGroupsSaga(): any {
    yield all([
        fork(getMyDataSourceByGroupsAction),
    ]);
}

export default getMyDataSourceByGroupsSaga;
