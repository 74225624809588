import { SoftwareActionTypes } from './constants';

const GET_SOFTWARE_INITIAL_STATE = {
    getsoftware: [],
    loading: false,
};

const GET_TASK_SOFTWARE_INITIAL_STATE = {
    getTask: [],
    loading: false,
};

const ADD_TASK_SOFTWARE_INITIAL_STATE = {
    addTask: [],
    loading: false,
};

const GET_REPEAT_INITIAL_STATE = {
    getRepeatSchedule: [],
    loading: false,
};

const GET_SOFTWARE_NAME_INITIAL_STATE = {
    getSoftwareName: [],
    loading: false,
};
const ADD_SCHEDULE_SCAN_INITIAL_STATE = {
    addScheduleScan: [],
    loading: false,
};
const ADD_SCAN_ON_DEMAND_INITIAL_STATE = {
    onDemandSoftwareScanning: [],
    loading: false,
};
const GET_USER_MAIL_INITIAL_STATE = {
    getUserMail: [],
    loading: false,
};

// ---------scan get --------
const GET_SCAN_INITIAL_STATE = {
    getScan: [],
    loading: false,
};

const GET_PREVIOUS_SCAN_INITIAL_STATE = {
    getPreviousScan: [],
    loading: false,
};

const GET_PREVIOUS_ASSET_INITIAL_STATE = {
    getPreviousAsset: [],
    loading: false,
};

const DELETE_SOFTWARE_INITIAL_STATE = {
    deleteSoftware: [],
    loading: false,
};
const EDIT_SCAN_SCHEDULE_INITIAL_STATE = {
    editScanSchedule: [],
    loading: false,
};
const STATUS_SOFTWARE_INITIAL_STATE = {
    statusSoftware: [],
    loading: false,
};

const GET_USER_INITIAL_STATE = {
    getUser: [],
    loading: false,
};
const getSoftwareReducer = (state = GET_SOFTWARE_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.GET_SORTWARE_LOADING:
            return {
                getsoftware: state.getsoftware,
                loading: true,
            };
        case SoftwareActionTypes.GET_SORTWARE_SUCCESS:
            return {
                getsoftware: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.GET_SORTWARE_ERROR:
            return {
                getsoftware: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

const getTaskReducer = (state = GET_TASK_SOFTWARE_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.GET_TASK_SORTWARE_LOADING:
            return {
                getTask: state.getTask,
                loading: true,
            };
        case SoftwareActionTypes.GET_TASK_SORTWARE_SUCCESS:
            return {
                getTask: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.GET_TASK_SORTWARE_ERROR:
            return {
                getTask: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

const addTaskReducer = (state = ADD_TASK_SOFTWARE_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.ADD_TASK_SORTWARE_LOADING:
            return {
                addTask: state.addTask,
                loading: true,
            };
        case SoftwareActionTypes.ADD_TASK_SORTWARE_SUCCESS:
            return {
                addTask: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.ADD_TASK_SORTWARE_ERROR:
            return {
                addTask: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.ADD_TASK_SORTWARE_RESET:
            return ADD_TASK_SOFTWARE_INITIAL_STATE;
        default:
            return state;
    }
};

const addScanSchduleReducer = (state = ADD_SCHEDULE_SCAN_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.ADD_SCHEDULE_SCAN_LOADING:
            return {
                addScheduleScan: state.addScheduleScan,
                loading: true,
            };
        case SoftwareActionTypes.ADD_SCHEDULE_SCAN_SUCCESS:
            return {
                addScheduleScan: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.ADD_SCHEDULE_SCAN_ERROR:
            return {
                addScheduleScan: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.ADD_SCHEDULE_SCAN_RESET:
            return ADD_SCHEDULE_SCAN_INITIAL_STATE;
        default:
            return state;
    }
};

const addSoftwareScanReducer = (state = ADD_SCAN_ON_DEMAND_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_LOADING:
            return {
                onDemandSoftwareScanning: state.onDemandSoftwareScanning,
                loading: true,
            };
        case SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_SUCCESS:
            return {
                onDemandSoftwareScanning: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_ERROR:
            return {
                onDemandSoftwareScanning: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.ADD_SORTWARE_FOR_SCANNING_RESET:
            return ADD_SCAN_ON_DEMAND_INITIAL_STATE;
        default:
            return state;
    }
};

// const getRepeatReducer = (state = GET_REPEAT_INITIAL_STATE, action) => {
//     switch (action.type) {
//         case SoftwareActionTypes.GET_REPEAT_LOADING:
//             return {
//                 getRepeat: state.getRepeat,
//                 loading: true,
//             };
//         case SoftwareActionTypes.GET_REPEAT_SUCCESS:
//             return {
//                 getRepeat: action.payload,
//                 loading: false,
//             };
//         case SoftwareActionTypes.GET_REPEAT_ERROR:
//             return {
//                 getRepeat: action.payload,
//                 loading: false,
//             };

//         default:
//             return state;
//     }
// };

const getScheduleRepeatReducer = (state = GET_REPEAT_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.GET_REPEAT_SCHEDULE_LOADING:
            return {
                getRepeatSchedule: state.getRepeatSchedule,
                loading: true,
            };
        case SoftwareActionTypes.GET_REPEAT_SCHEDULE_SUCCESS:
            return {
                getRepeatSchedule: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.GET_REPEAT_SCHEDULE_ERROR:
            return {
                getRepeatSchedule: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

// const getSoftwareNameReducer = (state = GET_SOFTWARE_NAME_INITIAL_STATE, action) => {
//     switch (action.type) {
//         case SoftwareActionTypes.GET_SOFTWARE_NAME_LOADING:
//             return {
//                 getSoftwareName: state.getSoftwareName,
//                 loading: true,
//             };
//         case SoftwareActionTypes.GET_SOFTWARE_NAME_SUCCESS:
//             return {
//                 getSoftwareName: action.payload,
//                 loading: false,
//             };
//         case SoftwareActionTypes.GET_SOFTWARE_NAME_ERROR:
//             return {
//                 getSoftwareName: action.payload,
//                 loading: false,
//             };

//         default:
//             return state;
//     }
// };

const getSoftwareNameReducer = (state = GET_SOFTWARE_NAME_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.GET_SOFTWARE_NAME_LOADING:
            return {
                getSoftwareName: state.getSoftwareName,
                loading: true,
            };
        case SoftwareActionTypes.GET_SOFTWARE_NAME_SUCCESS:
            return {
                getSoftwareName: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.GET_SOFTWARE_NAME_ERROR:
            return {
                getSoftwareName: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

const getUserMailReducer = (state = GET_USER_MAIL_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.GET_USER_MAIL_LOADING:
            return {
                getUserMail: state.getUserMail,
                loading: true,
            };
        case SoftwareActionTypes.GET_USER_MAIL_SUCCESS:
            return {
                getUserMail: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.GET_USER_MAIL_ERROR:
            return {
                getUserMail: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};
const getUserReducer = (state = GET_USER_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.GET_USER_LOADING:
            return {
                getUser: state.getUser,
                loading: true,
            };
        case SoftwareActionTypes.GET_USER_SUCCESS:
            return {
                getUser: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.GET_USER_ERROR:
            return {
                getUser: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

// ************Scan get **************
const getScanReducer = (state = GET_SCAN_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.GET_SCAN_SCHEDULE_LOADING:
            return {
                getScan: state.getScan,
                loading: true,
            };
        case SoftwareActionTypes.GET_SCAN_SCHEDULE_SUCCESS:
            return {
                getScan: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.GET_SCAN_SCHEDULE_ERROR:
            return {
                getScan: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

const getPreviousScanReducer = (state = GET_PREVIOUS_SCAN_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.GET_PREVIOUS_SCAN_LOADING:
            return {
                getPreviousScan: state.getPreviousScan,
                loading: true,
            };
        case SoftwareActionTypes.GET_PREVIOUS_SCAN_SUCCESS:
            return {
                getPreviousScan: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.GET_PREVIOUS_SCAN_ERROR:
            return {
                getPreviousScan: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

const getPreviousAssetReducer = (state = GET_PREVIOUS_ASSET_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.GET_PREVIOUS_ASSET_LOADING:
            return {
                getPreviousAsset: state.getPreviousAsset,
                loading: true,
            };
        case SoftwareActionTypes.GET_PREVIOUS_ASSET_SUCCESS:
            return {
                getPreviousAsset: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.GET_PREVIOUS_ASSET_ERROR:
            return {
                getPreviousAsset: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

// const deleteSoftwareReducer = (state = DELETE_SOFTWARE_INITIAL_STATE, action) => {
//     switch (action.type) {
//         case SoftwareActionTypes.DELETE_SOFTWARE_LOADING:
//             return {
//                 ...state,
//                 loading: true,
//             };
//         case SoftwareActionTypes.DELETE_SOFTWARE_SUCCESS:
//             // Remove the deleted item from the getSoftwareData array
//             const updatedSoftwareData = state.getSoftwareData.filter(itsm => itsm._id !== action.payload.softwareId);

//             return {
//                 ...state,
//                 getSoftwareData: updatedSoftwareData,
//                 loading: false,
//             };
//         case SoftwareActionTypes.DELETE_SOFTWARE_ERROR:
//             return {
//                 ...state,
//                 error: action.payload,
//                 loading: false,
//             };
//         case SoftwareActionTypes.DELETE_SOFTWARE_RESET:
//             return DELETE_SOFTWARE_INITIAL_STATE;
//         default:
//             return state;
//     }
// };
const deleteSoftwareReducer = (state = DELETE_SOFTWARE_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.DELETE_SOFTWARE_LOADING:
            return {
                deleteSoftware: state.deleteSoftware,
                loading: true,
            };
        case SoftwareActionTypes.DELETE_SOFTWARE_SUCCESS:
            return {
                deleteSoftware: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.DELETE_SOFTWARE_ERROR:
            return {
                deleteSoftware: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.DELETE_SOFTWARE_ERROR:
            return DELETE_SOFTWARE_INITIAL_STATE;
        case SoftwareActionTypes.DELETE_SOFTWARE_RESET:
            return DELETE_SOFTWARE_INITIAL_STATE;
        default:
            return state;
    }
};

const editScanScheduleReducer = (state = EDIT_SCAN_SCHEDULE_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.EDIT_SCHEDULE_SCAN_LOADING:
            return {
                editScanSchedule: state.editScanSchedule,
                loading: true,
            };
        case SoftwareActionTypes.EDIT_SCHEDULE_SCAN_SUCCESS:
            return {
                editScanSchedule: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.EDIT_SCHEDULE_SCAN_ERROR:
            return {
                editScanSchedule: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.EDIT_SCHEDULE_SCAN_ERROR:
            return EDIT_SCAN_SCHEDULE_INITIAL_STATE;
        case SoftwareActionTypes.EDIT_SCHEDULE_SCAN_RESET:
            return EDIT_SCAN_SCHEDULE_INITIAL_STATE;
        default:
            return state;
    }
};
const statusSoftwareReducer = (state = STATUS_SOFTWARE_INITIAL_STATE, action) => {
    switch (action.type) {
        case SoftwareActionTypes.STATUS_SOFTWARE_LOADING:
            return {
                statusSoftware: state.statusSoftware,
                loading: true,
            };
        case SoftwareActionTypes.STATUS_SOFTWARE_SUCCESS:
            return {
                statusSoftware: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.STATUS_SOFTWARE_ERROR:
            return {
                statusSoftware: action.payload,
                loading: false,
            };
        case SoftwareActionTypes.STATUS_SOFTWARE_RESET:
            return STATUS_SOFTWARE_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    getSoftwareReducer,
    getTaskReducer,
    addTaskReducer,
    getScanReducer,
    getPreviousScanReducer,
    getPreviousAssetReducer,
    getScheduleRepeatReducer,
    getSoftwareNameReducer,
    addScanSchduleReducer,
    getUserMailReducer,
    deleteSoftwareReducer,
    editScanScheduleReducer,
    statusSoftwareReducer,
    addSoftwareScanReducer,
    getUserReducer
};
