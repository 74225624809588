import { ProfileUpdateActionTypes } from "./constants";

export const profileUpateActions = (data): LayoutAction => ({
    type: ProfileUpdateActionTypes.PROFILE_UPDATE_FIRST,
    data
});

export const forgetPasswordActions = (data): LayoutAction => ({
    type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_FIRST,
    data
});


