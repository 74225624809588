import { IpAddressActionTypes } from './constants';
const GET_IP_ADDRESS_INITIAL_STATE = {
    getIpAddress: [],
    loading: false,
};
const GET_IP_ADDRESS_INFO_INITIAL_STATE = {
    getIpAddressInfo: [],
    loading: false,
};

const GET_IP_ADDRESS_SCAN_INITIAL_STATE = {
    getIpAddressScan: [],
    loading: false,
};
const GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_INITIAL_STATE = {
    getPreviousIpAddressScanList: [],
    loading: false,
};
const GET_IP_ADDRESS_SCAN_LIST_INITIAL_STATE = {
    getIpAddressScanList: [],
    loading: false,
};
const ADD_SCHEDULE_SCAN_IP_INITIAL_STATE = {
    addScheduleScanIp: [],
    loading: false,
};
const EDIT_SCAN_SCHEDULE_IP_INITIAL_STATE = {
    editScanScheduleIP: [],
    loading: false,
};

const STATUS_IP_ADDRESS_INITIAL_STATE = {
    statusIpAddress: [],
    loading: false,
};

const ADD_IP_SCAN_ON_DEMAND_INITIAL_STATE = {
    onDemandIpScanning: [],
    loading: false,
};

const getIpAddressReducer = (state = GET_IP_ADDRESS_INITIAL_STATE, action) => {
    switch (action.type) {
        case IpAddressActionTypes.GET_IP_ADDRESS_LOADING:
            return {
                getIpAddress: state.getIpAddress,
                loading: true,
            };
        case IpAddressActionTypes.GET_IP_ADDRESS_SUCCESS:
            return {
                getIpAddress: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.GET_IP_ADDRESS_ERROR:
            return {
                getIpAddress: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const getIpAddressInfoReducer = (state = GET_IP_ADDRESS_INFO_INITIAL_STATE, action) => {
    switch (action.type) {
        case IpAddressActionTypes.GET_IP_ADDRESS_INFO_LOADING:
            return {
                getIpAddressInfo: state.getIpAddressInfo,
                loading: true,
            };
        case IpAddressActionTypes.GET_IP_ADDRESS_INFO_SUCCESS:
            return {
                getIpAddressInfo: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.GET_IP_ADDRESS_INFO_ERROR:
            return {
                getIpAddressInfo: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
// const postIpAddressReducer = (state = POST_IP_ADDRESS_INITIAL_STATE, action) => {
//     switch (action.type) {
//         case IpAddressActionTypes.POST_IP_ADDRESS_LOADING:
//             return {
//                 postIPAddress: state.postIPAddress,
//                 loading: true,
//             };
//         case IpAddressActionTypes.POST_IP_ADDRESS_SUCCESS:
//             return {
//                 postIPAddress: action.payload,
//                 loading: false,
//             };
//         case IpAddressActionTypes.POST_IP_ADDRESS_ERROR:
//             return {
//                 postIPAddress: action.payload,
//                 loading: false,
//             };
//         case IpAddressActionTypes.POST_IP_ADDRESS_RESET:
//             return POST_IP_ADDRESS_INITIAL_STATE;
//         default:
//             return state;
//     }
// };
const getIpAddressScanReducer = (state = GET_IP_ADDRESS_SCAN_INITIAL_STATE, action) => {
    switch (action.type) {
        case IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LOADING:
            return {
                getIpAddressScan: state.getIpAddress,
                loading: true,
            };
        case IpAddressActionTypes.GET_IP_ADDRESS_SCAN_SUCCESS:
            return {
                getIpAddressScan: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.GET_IP_ADDRESS_SCAN_ERROR:
            return {
                getIpAddressScan: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
const getIpAddressScanListReducer = (state = GET_IP_ADDRESS_SCAN_LIST_INITIAL_STATE, action) => {
    switch (action.type) {
        case IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_LOADING:
            return {
                getIpAddressScanList: state.getIpAddressScanList,
                loading: true,
            };
        case IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_SUCCESS:
            return {
                getIpAddressScanList: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.GET_IP_ADDRESS_SCAN_LIST_ERROR:
            return {
                getIpAddressScanList: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
const getPreviousIpAddressScanListReducer = (state = GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_INITIAL_STATE, action) => {
    switch (action.type) {
        case IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_LOADING:
            return {
                getPreviousIpAddressScanList: state.getPreviousIpAddressScanList,
                loading: true,
            };
        case IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_SUCCESS:
            return {
                getPreviousIpAddressScanList: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST_ERROR:
            return {
                getPreviousIpAddressScanList: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const addScanScheduleIpReducer = (state = ADD_SCHEDULE_SCAN_IP_INITIAL_STATE, action) => {
    switch (action.type) {
        case IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_LOADING:
            return {
                addScheduleScanIp: state.addScheduleScanIp,
                loading: true,
            };
        case IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_SUCCESS:
            return {
                addScheduleScanIp: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_ERROR:
            return {
                addScheduleScanIp: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.ADD_IP_ADDRESS_SCAN_RESET:
            return ADD_SCHEDULE_SCAN_IP_INITIAL_STATE;
        default:
            return state;
    }
};

const statusIpAddressReducer = (state = STATUS_IP_ADDRESS_INITIAL_STATE, action) => {
    switch (action.type) {
        case IpAddressActionTypes.STATUS_IP_ADDRESS_LOADING:
            return {
                statusIpAddress: state.statusIpAddress,
                loading: true,
            };
        case IpAddressActionTypes.STATUS_IP_ADDRESS_SUCCESS:
            return {
                statusIpAddress: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.STATUS_IP_ADDRESS_ERROR:
            return {
                statusIpAddress: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.STATUS_IP_ADDRESS_RESET:
            return STATUS_IP_ADDRESS_INITIAL_STATE;
        default:
            return state;
    }
};

const addIpScanReducer = (state = ADD_IP_SCAN_ON_DEMAND_INITIAL_STATE, action) => {
    switch (action.type) {
        case IpAddressActionTypes.ADD_IP_FOR_SCANNING_LOADING:
            return {
                onDemandIpScanning: state.onDemandIpScanning,
                loading: true,
            };
        case IpAddressActionTypes.ADD_IP_FOR_SCANNING_SUCCESS:
            return {
                onDemandIpScanning: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.ADD_IP_FOR_SCANNING_ERROR:
            return {
                onDemandIpScanning: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.ADD_IP_FOR_SCANNING_RESET:
            return ADD_IP_SCAN_ON_DEMAND_INITIAL_STATE;
        default:
            return state;
    }
};

const editScanScheduleIpReducer = (state = EDIT_SCAN_SCHEDULE_IP_INITIAL_STATE, action) => {
    switch (action.type) {
        case IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_LOADING:
            return {
                editScanScheduleIP: state.editScanScheduleIP,
                loading: true,
            };
        case IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_SUCCESS:
            return {
                editScanScheduleIP: action.payload,
                loading: false,
            };
        case IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_ERROR:
            return {
                editScanScheduleIP: action.payload,
                loading: false,
            };

        case IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_ERROR:
            return EDIT_SCAN_SCHEDULE_IP_INITIAL_STATE;
        case IpAddressActionTypes.EDIT_IP_ADDRESS_SCAN_RESET:
            return EDIT_SCAN_SCHEDULE_IP_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    getIpAddressReducer,
    getIpAddressInfoReducer,
    getIpAddressScanReducer,
    getPreviousIpAddressScanListReducer,
    addScanScheduleIpReducer,
    editScanScheduleIpReducer,
    getIpAddressScanListReducer,
    statusIpAddressReducer,
    addIpScanReducer
};
