import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminGroupsActionTypes } from './constants';
import {
    adminNdSiteAdminGetGroupsListEndPoint,
    adminNdSiteAdminAddGroupstEndPoint,
    adminNdSiteAdminEditGroupstEndPoint,
    adminNdSiteAdminStatusGroupstEndPoint,
    adminNdSiteAdminDeleteGroupstEndPoint,
    adminNdSiteAdminGetGroupMngmntEndPoint,
    adminNdSiteAdminGetGroupUsersEndPoint,
    adminNdSiteAdminAssignGroupsDeleteEndPoint

} from './api';

// start cash day Report

function* callGetGroupsListAdminNdSiteAdmin(id) {
    try {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminGetGroupsListEndPoint, id);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callAddGroupsAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAddGroupstEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callEditGroupsAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminEditGroupstEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: {},
        });
    }
}

function* callStatusGroupsAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminStatusGroupstEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callDeleteGroupsAdminNdSiteAdmin(id) {
    try {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminDeleteGroupstEndPoint, id);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callGetGroupsMngmntAdminNdSiteAdmin(id) {
    try {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminGetGroupMngmntEndPoint, id);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callGetGroupsUsersAdminNdSiteAdmin(id) {
    try {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminGetGroupUsersEndPoint, id);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });

        } else {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callDataMngmntEmpty() {
    yield put({
        type: AdminNdSiteAdminGroupsActionTypes.DATA_MNGMNT_EMPTY_SUCCESS,
        payload: {},
    });

}

function* callAccountUsersEmptyEmpty() {
    yield put({
        type: AdminNdSiteAdminGroupsActionTypes.USERS_ACCOUNT_EMTPTY_SUCCESS,
        payload: {},
    });

}

function* callAssignGroupsDeleteAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAssignGroupsDeleteEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminGroupsActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });

}

// end cash day Report List

export function* getGroupsListAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_LIST_ADMIN_ND_SITE_ADMIN,
        callGetGroupsListAdminNdSiteAdmin
    );
}

export function* addGroupsAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminGroupsActionTypes.ADD_GROUPS_ADMIN_ND_SITE_ADMIN,
        callAddGroupsAdminNdSiteAdmin
    );
}

export function* editGroupsAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminGroupsActionTypes.EDIT_GROUPS_ADMIN_ND_SITE_ADMIN,
        callEditGroupsAdminNdSiteAdmin
    );
}

export function* statusGroupsAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminGroupsActionTypes.STATUS_GROUPS_ADMIN_ND_SITE_ADMIN,
        callStatusGroupsAdminNdSiteAdmin
    );
}

export function* deleteGroupsAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminGroupsActionTypes.DELETE_GROUPS_ADMIN_ND_SITE_ADMIN,
        callDeleteGroupsAdminNdSiteAdmin
    );
}

export function* getGroupsMngmntAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_MNGMNT_ADMIN_ND_SITE_ADMIN,
        callGetGroupsMngmntAdminNdSiteAdmin
    );
}

export function* getGroupsUsersAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminGroupsActionTypes.GET_GROUPS_USERS_ADMIN_ND_SITE_ADMIN,
        callGetGroupsUsersAdminNdSiteAdmin
    );
}

export function* dataMngmntEpmtyAction(): any {
    yield takeEvery(
        AdminNdSiteAdminGroupsActionTypes.DATA_MNGMNT_EMPTY,
        callDataMngmntEmpty
    );
}

export function* accountUsersEmptyAction(): any {
    yield takeEvery(
        AdminNdSiteAdminGroupsActionTypes.USERS_ACCOUNT_EMTPTY,
        callAccountUsersEmptyEmpty
    );
}

export function* assignGroupsDeleteAction(): any {
    yield takeEvery(
        AdminNdSiteAdminGroupsActionTypes.ASSIGN_GROUPS_DELETE_ADMIN_ND_SITE_ADMIN_FIRST,
        callAssignGroupsDeleteAdminNdSiteAdmin
    );
}

export function* stateEmptyAction(): any {
    yield takeEvery(
        AdminNdSiteAdminGroupsActionTypes.STATE_EMPTY_INTIAL_STATE,
        callStateEmpty
    );
}

function* getGroupsListAdminNdSiteAdminSaga(): any {
    yield all([
        fork(getGroupsListAdminNdSiteAdminAction),
        fork(addGroupsAdminNdSiteAdminAction),
        fork(editGroupsAdminNdSiteAdminAction),
        fork(statusGroupsAdminNdSiteAdminAction),
        fork(deleteGroupsAdminNdSiteAdminAction),
        fork(getGroupsMngmntAdminNdSiteAdminAction),
        fork(getGroupsUsersAdminNdSiteAdminAction),
        fork(dataMngmntEpmtyAction),
        fork(accountUsersEmptyAction),
        fork(assignGroupsDeleteAction),
        fork(stateEmptyAction)
    ]);
}

export default getGroupsListAdminNdSiteAdminSaga;
