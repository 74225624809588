import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { EmailVerifyActionTypes } from './constants';
import { emailVerifyEndPoint, resetPasswordConfirmEndPoint } from './api';

// start cash day Report

function* callEmailVerify(id) {
    try {
        yield put({
            type: EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_LOADING,
            payload: {},
        });
        const response = yield call(emailVerifyEndPoint, id);
        if (response.data.status) {
            yield put({
                type: EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_ERROR,
            payload: error,
        });
    }
}


// end cash day Report List

function* callResetPasswordConfirm(id) {
    try {
        yield put({
            type: EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_LOADING,
            payload: {},
        });
        const response = yield call(resetPasswordConfirmEndPoint, id);
        if (response.status === 200) {
            yield put({
                type: EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_ERROR,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_ERROR,
            payload: {},
        });
    }
}

export function* emailVerifySecond(): any {
    yield takeEvery(EmailVerifyActionTypes.EMAIL_VERIFY_WITH_TOKEN_FIRST, callEmailVerify);
}
export function* callResetPasswordConfirmSaga(): any {
    yield takeEvery(EmailVerifyActionTypes.RESET_PASSWORD_WITH_TOKEN_FIRST, callResetPasswordConfirm);
}

function* emailVerifySaga(): any {
    yield all([fork(emailVerifySecond), fork(callResetPasswordConfirmSaga)]);
}

export default emailVerifySaga;
