import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminAssetsGetApiEndPoint(params: any): any {
    const { companyId, search, limit, skip, type, softwareId, } = params?.data;
    const companyIdToSend = companyId || 1;
    const softwareIdToSend = softwareId || '';
    return api.get(`${URL.GET_ASSETS}company_id=${companyIdToSend}&software_id=${softwareIdToSend}&type=${type}&skip=${skip}&limit=${limit}&search=${search}`);
}
function getSoftwareListEndPoint(params: any): any {
    const { companyId, } = params?.data;
    const companyIdToSend = companyId || 1;
    return api.get(`${URL.GET_SOFTWARE_LIST}/${companyIdToSend}`);
}

function adminNdSiteAdminAssetsAddApiEndPoint(params: any): any {
    const AddAssetsData = params?.data;
    return api.create(URL.ADD_ASSETS, AddAssetsData);
}

function updateAssetsEndPoint(params: any): any {
    const AddAssetsData = params?.data;
    return api.update(URL.UPDATE_ASSETS, AddAssetsData);
}

function adminNdSiteAdminAssetsStatusApiEndPoint(params: any): any {
    const statusAssetsData = params?.data;
    return api.create(URL.ASSETS_Status, statusAssetsData);
}

function adminNdSiteAdminAssetsCsvApiEndPoint(params: any): any {
    const csvAssetsData = params?.data;
    return api.create(URL.ASSETS_CSV, csvAssetsData);
}

function deleteAssetsEndpoint(params: any): any {
    const assetData = params?.data;
    console.log({ assetData })
    return api.create(URL.DELETE_ASSETS, assetData);
}

export {
    adminNdSiteAdminAssetsGetApiEndPoint,
    adminNdSiteAdminAssetsAddApiEndPoint,
    updateAssetsEndPoint,
    adminNdSiteAdminAssetsStatusApiEndPoint,
    adminNdSiteAdminAssetsCsvApiEndPoint,
    getSoftwareListEndPoint,
    deleteAssetsEndpoint
};
