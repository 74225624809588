import { AdminNdSiteAdminVChartActionTypes } from './constants';
export const getVChartByCompanyIdAction = (data): LayoutAction => {
    return {
        type: AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        data
    };
};

export const addVChartByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const EditVChartByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const statusVChartByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const csvVChartByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const stateEmptydAction = (): LayoutAction => ({
    type: AdminNdSiteAdminVChartActionTypes.STATE_EMPTY_INTIAL_STATE,

});
