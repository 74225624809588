export const AdminNdSiteAdminInstanceActionTypes = {
    GET_INSTANCE_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_INSTANCE_ADMIN_ND_SITE_ADMIN',
    GET_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR',

    GET_INSTANCE_PLAN: '@@/layout/GET_INSTANCE_PLAN',
    GET_INSTANCE_PLAN_LOADING: '@@/layout/GET_INSTANCE_PLAN_LOADING',
    GET_INSTANCE_PLAN_SUCCESS: '@@/layout/GET_INSTANCE_PLAN_SUCCESS',
    GET_INSTANCE_PLAN_ERROR: '@@/layout/GET_INSTANCE_PLAN_ERROR',

    ADD_INSTANCE_ADMIN_ND_SITE_ADMIN: '@@/layout/ADD_INSTANCE_ADMIN_ND_SITE_ADMIN',
    ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING',
    ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR',
    ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET',

    EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN: '@@/layout/EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN',
    EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING',
    EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS',
    EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR',
    EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET',

    STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN: '@@/layout/STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN',
    STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING',
    STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS',
    STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR',
    STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET',

    DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN: '@@/layout/DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN',
    DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING',
    DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS',
    DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR',
    DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET',

    ADD_LICENCE_ADMIN_ND_SITE_ADMIN: '@@/layout/ADD_LICENCE_ADMIN_ND_SITE_ADMIN',
    ADD_LICENCE_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/ADD_LICENCE_ADMIN_ND_SITE_ADMIN_LOADING',
    ADD_LICENCE_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/ADD_LICENCE_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ADD_LICENCE_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/ADD_LICENCE_ADMIN_ND_SITE_ADMIN_ERROR',
    ADD_LICENCE_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/ADD_LICENCE_ADMIN_ND_SITE_ADMIN_RESET',

    ACTIVE_USER_ADMIN_ND_SITE_ADMIN: '@@/layout/ACTIVE_USER_ADMIN_ND_SITE_ADMIN',
    ACTIVE_USER_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/ACTIVE_USER_ADMIN_ND_SITE_ADMIN_LOADING',
    ACTIVE_USER_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/ACTIVE_USER_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ACTIVE_USER_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/ACTIVE_USER_ADMIN_ND_SITE_ADMIN_ERROR',
    ACTIVE_USER_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/ACTIVE_USER_ADMIN_ND_SITE_ADMIN_RESET',
};
