import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminGetLogsEndPoint(params: any): any {
    const { search, limit, page, cmId } = params?.data;
    return api.get(`${URL.LOGS_GET}search=${search}&limit=${limit}&page=${page}&id=${cmId}`);
}

export { adminNdSiteAdminGetLogsEndPoint };
