export const ForcePasswordActionTypes = {
    PUT_FORCE_PASSWORD_FIRST: '@@/layout/PUT_FORCE_PASSWORD_FIRST',
    PUT_FORCE_PASSWORD_LOADING:
        '@@/layout/PUT_FORCE_PASSWORD_LOADING',
    PUT_FORCE_PASSWORD_SUCCESS:
        '@@/layout/PUT_FORCE_PASSWORD_SUCCESS',
    PUT_FORCE_PASSWORD_ERROR:
        '@@/layout/PUT_FORCE_PASSWORD_ERROR',
    PUT_FORCE_PASSWORD_RESET:
        '@@/layout/PUT_FORCE_PASSWORD_RESET'


};
