import { UsersMyDevicesActionTypes } from "./constants";

const GET_MY_DEVICES_BY_SELECT_ASSETS_INITIAL_STATE = {
    getMyDevicesBySelectAssets: [],
    loading: false
}

const DELETE_MY_DEVICES_BY_SELECT_ASSETS_INITIAL_STATE = {
    deleteMyDevicesBySelectAssets: '',
    loading: false
}

const myDevicesGetBySelectAssetsReducer = (state = GET_MY_DEVICES_BY_SELECT_ASSETS_INITIAL_STATE, action) => {
    switch (action.type) {
        case UsersMyDevicesActionTypes.GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_LOADING:
            return {
                getMyDevicesBySelectAssets: state.getMyDevicesBySelectAssets,
                loading: true
            }
        case UsersMyDevicesActionTypes.GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_SUCCESS:
            return {
                getMyDevicesBySelectAssets: action.payload,
                loading: false
            }
        case UsersMyDevicesActionTypes.GET_MY_DEVICES_GET_BY_SELECT_ASSETS_USER_ERROR:
            return {
                getMyDevicesBySelectAssets: action.payload,
                loading: false
            }
        default: return state
    }

}

const myDevicesDeleteBySelectAssetsReducer = (state = DELETE_MY_DEVICES_BY_SELECT_ASSETS_INITIAL_STATE, action) => {
    switch (action.type) {
        case UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_LOADING:
            return {
                deleteMyDevicesBySelectAssets: state.deleteMyDevicesBySelectAssets,
                loading: true
            }
        case UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_SUCCESS:
            return {
                deleteMyDevicesBySelectAssets: action.payload,
                loading: false
            }
        case UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_ERROR:
            return {
                deleteMyDevicesBySelectAssets: action.payload,
                loading: false
            }
        case UsersMyDevicesActionTypes.DELETE_MY_DEVICES_DELETE_BY_SELECT_ASSETS_USER_RESET:
            return DELETE_MY_DEVICES_BY_SELECT_ASSETS_INITIAL_STATE
        default: return state
    }

}


export { myDevicesGetBySelectAssetsReducer, myDevicesDeleteBySelectAssetsReducer }