import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function createAdminApiEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.ADMIN_CREATE, data);
}

function updateAdminApiEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.ADMIN_UPDATE, data);
}

function getAdminApiEndPoint(params: any): any {
    const { search, limite, page } = params?.data
    return api.get(`${URL.ADMIN_GET}search=${search}&limit=${limite}&page=${page}`);
}

export { createAdminApiEndPoint, getAdminApiEndPoint, updateAdminApiEndPoint };
