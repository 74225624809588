import { AdminNdSiteAdminAccountsActionTypes } from './constants';
export const getAccountsByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminAccountsActionTypes.GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const addAccountsByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminAccountsActionTypes.ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const editAccountsByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminAccountsActionTypes.EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const statusAccountsByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminAccountsActionTypes.STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const assignGroupsAccountsByCompanyIdAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminAccountsActionTypes.ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    data,
});

export const getGroupsAccountsByCompanyIdAction = (id): LayoutAction => ({
    type: AdminNdSiteAdminAccountsActionTypes.GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    id,
});

export const userPasswordResetAccountsByCompanyIdAction = (id): LayoutAction => ({
    type: AdminNdSiteAdminAccountsActionTypes.USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
    id,
});

export const stateEmptydAction = (id): LayoutAction => ({
    type: AdminNdSiteAdminAccountsActionTypes.STATE_EMPTY_INTIAL_STATE,

});

