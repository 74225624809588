import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { RiskDahboardActionTypes } from './constant';
import { getDashboardEndPoint, getVulnerabilityEndPoint, japaneseRemedationEndPoint, riskRemedationEndPoint } from './api';

// start cash day Report

function* callGetRiskdashboard(data) {
    try {
        yield put({
            type: RiskDahboardActionTypes.GET_RISKDASHBOARD_LOADING,
            payload: {},
        });
        const response = yield call(getDashboardEndPoint, data);
        if (response.data.status) {
            yield put({
                type: RiskDahboardActionTypes.GET_RISKDASHBOARD_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: RiskDahboardActionTypes.GET_RISKDASHBOARD_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: RiskDahboardActionTypes.GET_RISKDASHBOARD_ERROR,
            payload: error,
        });
    }
}

function* callGetRiskVulnerability(data) {
    try {
        yield put({
            type: RiskDahboardActionTypes.GET_VULNERABILITY_LOADING,
            payload: {},
        });
        const response = yield call(getVulnerabilityEndPoint, data);
        if (response.data.status) {
            yield put({
                type: RiskDahboardActionTypes.GET_VULNERABILITY_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: RiskDahboardActionTypes.GET_VULNERABILITY_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: RiskDahboardActionTypes.GET_VULNERABILITY_ERROR,
            payload: error,
        });
    }
}

function* callRiskRemedation(data) {
    try {
        yield put({
            type: RiskDahboardActionTypes.POST_RISK_REMEDATION_LOADING,
            payload: {},
        });
        const response = yield call(riskRemedationEndPoint, data);
        if (response.data.status) {
            yield put({
                type: RiskDahboardActionTypes.POST_RISK_REMEDATION_SUCCESS,
                payload: { ...response.data },
            });
            // yiels
        } else {
            yield put({
                type: RiskDahboardActionTypes.POST_RISK_REMEDATION_ERROR,
                payload: { ...response.data },
            });
            // yield put({
            //     type: RiskDahboardActionTypes.POST_RISK_REMEDATION_RESET,
            //     payload: {},
            // });
        }
    } catch (error) {
        yield put({
            type: RiskDahboardActionTypes.POST_RISK_REMEDATION_ERROR,
            payload: error,
        });
        yield put({
            type: RiskDahboardActionTypes.POST_RISK_REMEDATION_RESET,
            payload: {},
        });
    }
}

function* callJapaneseRemedation(data) {
    try {
        yield put({
            type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_LOADING,
            payload: {},
        });
        const response = yield call(japaneseRemedationEndPoint, data);
        if (response.data.status) {
            yield put({
                type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_SUCCESS,
                payload: { ...response.data },
            });
            // yiels
        } else {
            yield put({
                type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_ERROR,
                payload: { ...response.data },
            });
            // yield put({
            //     type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_RESET,
            //     payload: {},
            // });
        }
    } catch (error) {
        yield put({
            type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_ERROR,
            payload: error,
        });
        yield put({
            type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_RESET,
            payload: {},
        });
    }
}

// end cash day Report List
export function* getRiskDashboardAction(): any {
    yield takeEvery(RiskDahboardActionTypes.GET_RISKDASHBOARD_FIRST, callGetRiskdashboard);
}
export function* getVulAction(): any {
    yield takeEvery(RiskDahboardActionTypes.GET_VULNERABILITY_FIRST, callGetRiskVulnerability);
    yield takeEvery(RiskDahboardActionTypes.POST_RISK_REMEDATION_FIRST, callRiskRemedation);
    yield takeEvery(RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_FIRST, callJapaneseRemedation);
}

function* getRiskdashboardSaga(): any {
    yield all([fork(getRiskDashboardAction)]);
    yield all([fork(getVulAction)]);

}

export default getRiskdashboardSaga;
