import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { UserNewSFildActionTypes } from './constants';
import { getNewFildEndPoint, } from './api';


// start cash day Report

function* callGetNewsFild(id) {
    try {
        yield put({
            type: UserNewSFildActionTypes.GET_NEWS_FILD_USER_LOADING,
            payload: {},
        });
        const response = yield call(getNewFildEndPoint, id);
        if (response.data.status) {
            yield put({
                type: UserNewSFildActionTypes.GET_NEWS_FILD_USER_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: UserNewSFildActionTypes.GET_NEWS_FILD_USER_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: UserNewSFildActionTypes.GET_NEWS_FILD_USER_ERROR,
            payload: error,
        });
    }
}
// end cash day Report List


export function* getNewFildAction(): any {
    yield takeEvery(UserNewSFildActionTypes.GET_NEWS_FILD_USER, callGetNewsFild);
}


function* getNewFildSaga(): any {
    yield all([
        fork(getNewFildAction),
    ]);
}

export default getNewFildSaga;
