import { APICore } from '../../../../helpers/api/apiCore';
import * as URL from "../../../../constants/endPoint"

const api = new APICore();

function getNewFildEndPoint(params: any): any {
    const { search } = params.id
    return api.get(`${URL.NEW_FILD_GET}search=${search}`);
}

export { getNewFildEndPoint }