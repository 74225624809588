import { AdminNdSiteAdminLogsActionTypes } from "./constants";

export const getLogsAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN,
    data

});

export const stateEmptydAction = (): LayoutAction => ({
    type: AdminNdSiteAdminLogsActionTypes.STATE_EMPTY_INTIAL_STATE,

});


