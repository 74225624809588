import { AdminNdSiteAdminCompanyActionTypes } from './constants';

const GET_COMPANY_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    getCompanyAdmin: [],
    loading: false,
};

const getCompanyAdminNdSiteAdminReducer = (state = GET_COMPANY_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getCompanyAdmin: state.getCompanyAdmin,
                loading: true,
            };
        case AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getCompanyAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getCompanyAdmin: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export { getCompanyAdminNdSiteAdminReducer };
