import { RiskDahboardActionTypes } from "./constant";

export const getRiskDashboardActions = (data): LayoutAction => ({
    type: RiskDahboardActionTypes.GET_RISKDASHBOARD_FIRST,
    data,
});

export const getRiskVulnerabilityActions = (data): LayoutAction => ({
    type: RiskDahboardActionTypes.GET_VULNERABILITY_FIRST,
    data,
});

export const riskRemedationAction = (data): LayoutAction => ({
    type: RiskDahboardActionTypes.POST_RISK_REMEDATION_FIRST,
    data,
});

export const japaneseRemedationAction = (data): LayoutAction => ({
    type: RiskDahboardActionTypes.POST_JAPANESE_REMEDATION_FIRST,
    data,
});