import { APICore } from '../../../../helpers/api/apiCore';
import * as URL from "../../../../constants/endPoint"

const api = new APICore();

function userMyDevicesGetAssetsEndPoint(params: any): any {
    return api.get(URL.MY_DEVICES_GET, params);
}

function userMyDevicesDeleteAssetsEndPoint(id: any): any {
    const deleteId = id?.payload
    return api.delete(`/api/instance/user/asset/${deleteId}`);
}

export { userMyDevicesGetAssetsEndPoint, userMyDevicesDeleteAssetsEndPoint }