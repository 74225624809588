import { UserNewSFildActionTypes } from './constants';

const GET_NEWS_FILD_INITIAL_STATE = {
    newsFild: [],
    loading: false
}

const getNewsFildReducer = (state = GET_NEWS_FILD_INITIAL_STATE, action) => {
    switch (action.type) {
        case UserNewSFildActionTypes.GET_NEWS_FILD_USER_LOADING:
            return {
                newsFild: state.newsFild,
                loading: true
            }
        case UserNewSFildActionTypes.GET_NEWS_FILD_USER_SUCCESS:
            return {
                newsFild: action.payload,
                loading: false
            }
        case UserNewSFildActionTypes.GET_NEWS_FILD_USER_ERROR:
            return {
                newsFild: action.payload,
                loading: false
            }
        default: return state
    }
}

export { getNewsFildReducer }