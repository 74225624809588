export const DocumentsActionTypes = {

    GET_DOCUMENTS_SITE_ADMIN: '@@/layout/GET_DOCUMENTS_SITE_ADMIN',
    GET_DOCUMENTS_SITE_ADMIN_LOADING: '@@/layout/GET_DOCUMENTS_SITE_ADMIN_LOADING',
    GET_DOCUMENTS_SITE_ADMIN_SUCCESS: '@@/layout/GET_DOCUMENTS_SITE_ADMIN_SUCCESS',
    GET_DOCUMENTS_SITE_ADMIN_ERROR: '@@/layout/GET_DOCUMENTS_SITE_ADMIN_ERROR',

    ADD_DOCUMENTS_SITE_ADMIN: '@@/layout/ADD_DOCUMENTS_SITE_ADMIN',
    ADD_DOCUMENTS_SITE_ADMIN_LOADING: '@@/layout/ADD_DOCUMENTS_SITE_ADMIN_LOADING',
    ADD_DOCUMENTS_SITE_ADMIN_SUCCESS: '@@/layout/ADD_DOCUMENTS_SITE_ADMIN_SUCCESS',
    ADD_DOCUMENTS_SITE_ADMIN_ERROR: '@@/layout/ADD_DOCUMENTS_SITE_ADMIN_ERROR',
    ADD_DOCUMENTS_SITE_ADMIN_RESET: '@@/layout/ADD_DOCUMENTS_SITE_ADMIN_RESET',
  
    ADD_DOCUMENTS_SITE_ADMIN_STATUS: '@@/layout/ADD_DOCUMENTS_SITE_ADMIN_STATUS',
    ADD_DOCUMENTS_SITE_ADMIN_STATUS_LOADING: '@@/layout/ADD_DOCUMENTS_SITE_ADMIN_STATUS_LOADING',
    ADD_DOCUMENTS_SITE_ADMIN_STATUS_SUCCESS: '@@/layout/ADD_DOCUMENTS_SITE_ADMIN_STATUS_SUCCESS',
    ADD_DOCUMENTS_SITE_ADMIN_STATUS_ERROR: '@@/layout/ADD_DOCUMENTS_SITE_ADMIN_STATUS_ERROR',
    ADD_DOCUMENTS_SITE_ADMIN_STATUS_RESET: '@@/layout/ADD_DOCUMENTS_SITE_ADMIN_STATUS_RESET',


    DELETE_DOCUMENTS_SITE_ADMIN: '@@/layout/DELETE_DOCUMENTS_SITE_ADMIN',
    DELETE_DOCUMENTS_SITE_ADMIN_LOADING: '@@/layout/DELETE_DOCUMENTS_SITE_ADMIN_LOADING',
    DELETE_DOCUMENTS_SITE_ADMIN_SUCCESS: '@@/layout/DELETE_DOCUMENTS_SITE_ADMIN_SUCCESS',
    DELETE_DOCUMENTS_SITE_ADMIN_ERROR: '@@/layout/DELETE_DOCUMENTS_SITE_ADMIN_ERROR',
    DELETE_DOCUMENTS_SITE_ADMIN_RESET: '@@/layout/DELETE_DOCUMENTS_SITE_ADMIN_RESET',
}
