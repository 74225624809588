import { TaskActionTypes } from "./constants";

const GET_TASK_LIST_INITIAL_STATE = {
    getTaskList: [],
    loading: false
}

const GET_TASK_STATUS_INITIAL_STATE = {
    getTaskStatus: [],
    loading: false
}

const ADD_START_TASK_INITIAL_STATE = {
    addStartTask: [],
    loading: false
}
const ADD_COMMENT_INITIAL_STATE = {
    addComment: [],
    loading: false
}


const getTaskListReducer = (state = GET_TASK_LIST_INITIAL_STATE, action) => {
    switch (action.type) {
        case TaskActionTypes.GET_TASK_LIST_LOADING:
            return {
                getTaskList: state.getTaskList,
                loading: true
            }
        case TaskActionTypes.GET_TASK_LIST_SUCCESS:
            return {
                getTaskList: action.payload,
                loading: false
            }
        case TaskActionTypes.GET_TASK_LIST_ERROR:
            return {
                getTaskList: action.payload,
                loading: false
            }
        default: return state
    }
}

const getTaskStatusReducer = (state = GET_TASK_STATUS_INITIAL_STATE, action) => {
    switch (action.type) {
        case TaskActionTypes.GET_TASK_STATUS_LOADING:
            return {
                getTaskStatus: state.getTaskStatus,
                loading: true
            }
        case TaskActionTypes.GET_TASK_STATUS_SUCCESS:
            return {
                getTaskStatus: action.payload,
                loading: false
            }
        case TaskActionTypes.GET_TASK_STATUS_ERROR:
            return {
                getTaskStatus: action.payload,
                loading: false
            }
        default: return state
    }
}

const addStartTaskReducer = (state = ADD_START_TASK_INITIAL_STATE, action) => {
    switch (action.type) {
        case TaskActionTypes.ADD_START_TASK_LOADING:
            return {
                addStartTask: state.addStartTask,
                loading: true
            }
        case TaskActionTypes.ADD_START_TASK_SUCCESS:
            return {
                addStartTask: action.payload,
                loading: false
            }
        case TaskActionTypes.ADD_START_TASK_ERROR:
            return {
                addStartTask: action.payload,
                loading: false
            }
        case TaskActionTypes.ADD_START_TASK_RESET:
            return ADD_START_TASK_INITIAL_STATE
        default: return state
    }
}
const addCommentReducer = (state = ADD_COMMENT_INITIAL_STATE, action) => {
    switch (action.type) { 
        case TaskActionTypes.ADD_COMMENT_LOADING:
            return {
                addComment: state.addComment,
                loading: true
            }
        case TaskActionTypes.ADD_COMMENT_SUCCESS:
            return {
                addComment: action.payload,
                loading: false
            }
        case TaskActionTypes.ADD_COMMENT_ERROR:
            return {
                addComment: action.payload,
                loading: false
            }
       
        default: return state
    }
}


export { getTaskListReducer, getTaskStatusReducer, addStartTaskReducer,addCommentReducer }