import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminComapnyApiEndPoint(): any {
    return api.get(URL.COMPANY_GET);
}

export { adminNdSiteAdminComapnyApiEndPoint };
