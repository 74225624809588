export const AdminNdSiteAdminDataMngntActionTypes = {
    GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN',
    GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR',

    ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN: '@@/layout/ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN',
    ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING',
    ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR',
    ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET',

    EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN: '@@/layout/EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN',
    EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING',
    EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS',
    EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR',
    EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET',

    CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN: '@@/layout/CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN',
    CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING',
    CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS',
    CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR',
    CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET',

    STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN: '@@/layout/STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN',
    STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING',
    STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS',
    STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR',
    STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET',

    ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN: '@@/layout/ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN',
    ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING',
    ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR',
    ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET:
        '@@/layout/ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET',

    GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN',
    GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR',
    GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET',

    ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN: '@@/layout/ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN',
    ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_LOADING',
    ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_ERROR',
    ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/ADD_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET',

    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',

};
