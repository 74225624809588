import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminVChartActionTypes } from './constants';
import {
    adminNdSiteAdminVChartGetApiEndPoint,
    adminNdSiteAdminVChartAddApiEndPoint,
    adminNdSiteAdminVChartEditApiEndPoint,
    adminNdSiteAdminVChartStatusApiEndPoint,
    adminNdSiteAdminVChartCsvApiEndPoint,
} from './api';

// start cash day Report

function* callGetVChartByCompanyIdAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminVChartGetApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callAddVChartByCompanyIdAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminVChartAddApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callEditVChartByCompanyIdAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminVChartEditApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStatusVChartByCompanyIdAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminVChartStatusApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callCsvVChartByCompanyIdAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminVChartCsvApiEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminVChartActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });
}
// end cash day Report List

export function* getVChartByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callGetVChartByCompanyIdAdminNdSiteAdmin
    );
}

export function* addVChartByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callAddVChartByCompanyIdAdminNdSiteAdmin
    );
}

export function* EditVChartByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callEditVChartByCompanyIdAdminNdSiteAdmin
    );
}

export function* statusVChartByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callStatusVChartByCompanyIdAdminNdSiteAdmin
    );
}

export function* csvVChartByComapnyIdAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN,
        callCsvVChartByCompanyIdAdminNdSiteAdmin
    );
}

export function* stateEmptyAction(): any {
    yield takeEvery(AdminNdSiteAdminVChartActionTypes.STATE_EMPTY_INTIAL_STATE, callStateEmpty);
}

function* getVChartByCompanyIdAdminNdSiteAdminSaga(): any {
    yield all([
        fork(getVChartByComapnyIdAdminNdSiteAdminAction),
        fork(addVChartByComapnyIdAdminNdSiteAdminAction),
        fork(EditVChartByComapnyIdAdminNdSiteAdminAction),
        fork(statusVChartByComapnyIdAdminNdSiteAdminAction),
        fork(csvVChartByComapnyIdAdminNdSiteAdminAction),
        fork(stateEmptyAction),
    ]);
}

export default getVChartByCompanyIdAdminNdSiteAdminSaga;
