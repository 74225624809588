export const AdminNdSiteAdminVChartActionTypes = {
    GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',

    ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
        '@@/layout/EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
        '@@/layout/STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',
};
