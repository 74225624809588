import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { TaskActionTypes } from './constants';
import { getTaskListEndPoint, getTaskStatusEndPoint, addStartTaskEndPoint, addCommentEndPoint } from './api';

// start cash day Report

function* callGetTaskList(data) {
    try {
        yield put({
            type: TaskActionTypes.GET_TASK_LIST_LOADING,
            payload: {},
        });
        const response = yield call(getTaskListEndPoint, data);
        if (response.data.status) {
            yield put({
                type: TaskActionTypes.GET_TASK_LIST_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: TaskActionTypes.GET_TASK_LIST_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: TaskActionTypes.GET_TASK_LIST_ERROR,
            payload: error,
        });
    }
}

function* callGetTaskStatus() {
    try {
        yield put({
            type: TaskActionTypes.GET_TASK_STATUS_LOADING,
            payload: {},
        });
        const response = yield call(getTaskStatusEndPoint);
        if (response.data.status) {
            yield put({
                type: TaskActionTypes.GET_TASK_STATUS_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: TaskActionTypes.GET_TASK_STATUS_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: TaskActionTypes.GET_TASK_STATUS_ERROR,
            payload: error,
        });
    }
}

function* callAddStartTask(data) {
    try {
        yield put({
            type: TaskActionTypes.ADD_START_TASK_LOADING,
            payload: {},
        });
        const response = yield call(addStartTaskEndPoint, data);
        if (response.data.status) {
            yield put({
                type: TaskActionTypes.ADD_START_TASK_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: TaskActionTypes.ADD_START_TASK_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: TaskActionTypes.ADD_START_TASK_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: TaskActionTypes.ADD_START_TASK_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: TaskActionTypes.ADD_START_TASK_ERROR,
            payload: error,
        });
        yield put({
            type: TaskActionTypes.ADD_START_TASK_RESET,
            payload: {},
        });
    }
}

function* callAddComment(data) {
    try {
        yield put({ type: TaskActionTypes.ADD_COMMENT_LOADING });

        const response = yield call(addCommentEndPoint, data);

        if (response.data.status) {
            yield put({
                type: TaskActionTypes.ADD_COMMENT_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: TaskActionTypes.ADD_COMMENT_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: TaskActionTypes.ADD_COMMENT_ERROR,
            payload: error,
        });
    } finally {
        yield put({ type: TaskActionTypes.ADD_COMMENT_RESET });
    }
}

export function* getTaskListAction(): any {
    yield takeEvery(TaskActionTypes.GET_TASK_LIST_FIRST, callGetTaskList);
}

export function* getTaskStatusAction(): any {
    yield takeEvery(TaskActionTypes.GET_TASK_STATUS_FIRST, callGetTaskStatus);
}

export function* addStartTaskAction(): any {
    yield takeLatest(TaskActionTypes.ADD_START_TASK_FIRST, callAddStartTask);
}
export function* addCommentAction(): any {
    yield takeEvery(TaskActionTypes.ADD_COMMENT_FIRST, callAddComment);
}

function* getTaskListSaga(): any {
    yield all([fork(getTaskListAction), fork(getTaskStatusAction), fork(addStartTaskAction), fork(addCommentAction)]);
}

export default getTaskListSaga;
