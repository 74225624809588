import { AdminNdSiteAdminLogsActionTypes } from './constants';

const GET_LOGS_ADMIN_INITIAL_STATE = {
    getlogsAdmin: [],
    loading: false,
};


const getLogsAdminReducer = (state = GET_LOGS_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getlogsAdmin: state.getlogsAdmin,
                loading: true,
            };
        case AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getlogsAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getlogsAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminLogsActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_LOGS_ADMIN_INITIAL_STATE

        default:
            return state;
    }
};


export { getLogsAdminReducer };
