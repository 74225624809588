export const AdminNdSiteAdminAccountsActionTypes = {
    GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/GET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',

    ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
        '@@/layout/ADD_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
        '@@/layout/EDIT_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
        '@@/layout/STATUS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
        '@@/layout/ASSIGN_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
        '@@/layout/GET_GROUPS_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
        '@@/layout/USER_PASSWORD_RESET_ACCOUNTS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',

};
