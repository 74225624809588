import { AdminNdSiteAdminAddSoftwareActionTypes } from './constants';

const POST_SOFTWARE_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    postSoftareAdminNdSiteAdmin: [],
    loading: false,
};

const GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    getSoftareAdminNdSiteAdmin: [],
    loading: false,
};

const GET_IT_DASHBOARD_INITIAL_STATE = {
    getItDashboard: [],
    loading: false,
};
const DEAFULT_SET_IT_DSBOARD_INIIAL_STATE = {
    dfltStData: '',
};
const POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    postIpAddressAdminNdSiteAdmin: [],
    loading: false,
};
const GET_TOTAL_ASSETS_SOFTWARE_INITIAL_STATE = {
    getTotalAssetsSoftware: [],
    loading: false,
};

const GET_SUPER_ADMIN_ACTIVEUSERS_INITIAL_STATE = {
    getActiveUsersSuperAdmin: [],
    loading: false,
};

const POST_VULNERABILITIES_DEFAULT_INITIAL_STATE = {
    postValnerabilityWithAndWithOut: [],
    loading: false,
};



const postAddSofterAdminNdSiteAdminReducer = (state = POST_SOFTWARE_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                postSoftareAdminNdSiteAdmin: state.postSoftareAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                postSoftareAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                postSoftareAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_ADD_SOFTWARE_ADMIN_ND_SITE_ADMIN_RESET:
            return POST_SOFTWARE_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const getSofterAdminNdSiteAdminReducer = (state = GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getSoftareAdminNdSiteAdmin: state.getSoftareAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getSoftareAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_SOFTWARE_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getSoftareAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const getItDashboardReducer = (state = GET_IT_DASHBOARD_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_DASHBOARD_LOADING:
            return {
                getItDashboard: state.getItDashboard,
                loading: true,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_DASHBOARD_SUCCESS:
            return {
                getItDashboard: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_DASHBOARD_ERROR:
            return {
                getItDashboard: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_DASHBOARD_RESET:
            return GET_IT_DASHBOARD_INITIAL_STATE;
        default:
            return state;
    }
};

// Total Software && Assets
const getItDashboardTotalSoftwareReducer = (state = GET_TOTAL_ASSETS_SOFTWARE_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_LOADING:
            return {
                getTotalAssetsSoftware: state.getTotalAssetsSoftware,
                loading: true,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_SUCCESS:
            return {
                getTotalAssetsSoftware: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_IT_TOTAL_SOFTWARE_ASSETS_DASHBOARD_ERROR:
            return {
                getTotalAssetsSoftware: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const dfltStDataITDshbrodReducer = (state = DEAFULT_SET_IT_DSBOARD_INIIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAddSoftwareActionTypes.DEFAULT_SET_IT_DASHBOARD_SUCCESS:
            return {
                getItDashboard: action.payload,
            };
        default:
            return state;
    }
};

// IP Address
const postIpAddressAdminNdSiteAdminReducer = (state = POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                postIpAddressAdminNdSiteAdmin: state.postIpAddressAdminNdSiteAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                postIpAddressAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                postIpAddressAdminNdSiteAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_RESET:
            return POST_IP_ADDRESS_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const getSuperAdminDashboardReducer = (state = GET_SUPER_ADMIN_ACTIVEUSERS_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_SUPER_ADMIN_DASHBOARD_LOADING:
            return {
                getActiveUsersSuperAdmin: state.getActiveUsersSuperAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_SUPER_ADMIN_DASHBOARD_SUCCESS:
            return {
                getActiveUsersSuperAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_SUPER_ADMIN_DASHBOARD_ERROR:
            return {
                getActiveUsersSuperAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.GET_SUPER_ADMIN_DASHBOARD_RESET:
            return GET_SUPER_ADMIN_ACTIVEUSERS_INITIAL_STATE;
        default:
            return state;
    }
};

const postVulnerabilityReducer = (state = POST_VULNERABILITIES_DEFAULT_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_LOADING:
            return {
                postValnerabilityWithAndWithOut: state.postValnerabilityWithAndWithOut,
                loading: true,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_SUCCESS:
            return {
                postValnerabilityWithAndWithOut: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_ERROR:
            return {
                postValnerabilityWithAndWithOut: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAddSoftwareActionTypes.POST_IT_TOTAL_SOFTWARE_WITHVULNERABILITY_DASHBOARD_RESET:
            return POST_VULNERABILITIES_DEFAULT_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    postAddSofterAdminNdSiteAdminReducer,
    getSofterAdminNdSiteAdminReducer,
    getItDashboardReducer,
    dfltStDataITDshbrodReducer,
    postIpAddressAdminNdSiteAdminReducer,
    getItDashboardTotalSoftwareReducer,
    getSuperAdminDashboardReducer,
    postVulnerabilityReducer,
};
