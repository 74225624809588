import { APICore } from '../../../../helpers/api/apiCore';
import * as URL from "../../../../constants/endPoint"

const api = new APICore();

function userMyDataSourceByGroupsEndPoint(params: any): any {
    const userId = params?.id
    return api.get(`${URL.MY_DATA_SOURCE_GET}/${userId}`);
}


export { userMyDataSourceByGroupsEndPoint }