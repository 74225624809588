import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminInstanceActionTypes } from './constants';
import {
    adminNdSiteAdminGetInstanceEndPoint,
    adminNdSiteAdminAddInstanceEndPoint,
    adminNdSiteAdminEditInstanceEndPoint,
    adminNdSiteAdminStatusInstanceEndPoint,
    adminNdSiteAdminDeleteInstanceEndPoint,
    AddLicenceEndPoint,
    activeEndPoint,
    getInstancePlansEndPoint
} from './api';

// start cash day Report

function* callGetInstanceAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminGetInstanceEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* callGetInstancePlan(data) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_LOADING,
            payload: {},
        });
        const response = yield call(getInstancePlansEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN_ERROR,
            payload: error,
        });
    }
}

function* callAddInstanceAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAddInstanceEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callEditInstanceAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminEditInstanceEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStatusInstanceAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminStatusInstanceEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* calldeleteInstanceAdminNdSiteAdmin(id) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminDeleteInstanceEndPoint, id);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callAddLicenceAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(AddLicenceEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            // yield put({
            //     type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_RESET,
            //     payload: {},
            // });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        // yield put({
        //     type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN_RESET,
        //     payload: {},
        // });
    }
}
function* callACtiveAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(activeEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

// end cash day Report List

export function* getInstanceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN,
        callGetInstanceAdminNdSiteAdmin
    );
}
export function* getInstanceAllPlan(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN,
        callGetInstancePlan
    );
}

export function* addInstanceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN,
        callAddInstanceAdminNdSiteAdmin
    );
}
export function* addlicenceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN,
        callAddLicenceAdminNdSiteAdmin
    );
}
export function* addActiveAdminNdSiteAdminAction(): any {
    yield takeEvery(AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN, callACtiveAdminNdSiteAdmin);
}

export function* editInstanceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN,
        callEditInstanceAdminNdSiteAdmin
    );
}

export function* statusInstanceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN,
        callStatusInstanceAdminNdSiteAdmin
    );
}
export function* deleteInstanceAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN,
        calldeleteInstanceAdminNdSiteAdmin
    );
}

function* getInstanceAdminNdSiteAdminSaga(): any {
    yield all([
        fork(getInstanceAdminNdSiteAdminAction),
        fork(addInstanceAdminNdSiteAdminAction),
        fork(editInstanceAdminNdSiteAdminAction),
        fork(statusInstanceAdminNdSiteAdminAction),
        fork(deleteInstanceAdminNdSiteAdminAction),
        fork(addlicenceAdminNdSiteAdminAction),
        fork(addActiveAdminNdSiteAdminAction),
        fork(getInstanceAllPlan),
    ]);
}

export default getInstanceAdminNdSiteAdminSaga;
