export const CreatAdminActionTypes = {
    CREATE_ADMIN_FIRST: '@@/layout/CREATE_ADMIN_FIRST',
    CREATE_ADMIN_LOADING: '@@/layout/CREATE_ADMIN_LOADING',
    CREATE_ADMIN_SUCCESS: '@@/layout/CREATE_ADMIN_SUCCESS',
    CREATE_ADMIN_ERROR: '@@/layout/CREATE_ADMIN_ERROR',
    CREATE_ADMIN_RESET: '@@/layout/CREATE_ADMIN_RESET',

    UPDATE_ADMIN_FIRST: '@@/layout/UPDATE_ADMIN_FIRST',
    UPDATE_ADMIN_LOADING: '@@/layout/UPDATE_ADMIN_LOADING',
    UPDATE_ADMIN_SUCCESS: '@@/layout/UPDATE_ADMIN_SUCCESS',
    UPDATE_ADMIN_ERROR: '@@/layout/UPDATE_ADMIN_ERROR',
    UPDATE_ADMIN_RESET: '@@/layout/UPDATE_ADMIN_RESET',

    GET_ADMIN_FIRST: '@@/layout/GET_ADMIN_FIRST',
    GET_ADMIN_LOADING: '@@/layout/GET_ADMIN_LOADING',
    GET_ADMIN_SUCCESS: '@@/layout/GET_ADMIN_SUCCESS',
    GET_ADMIN_ERROR: '@@/layout/GET_ADMIN_ERROR',

    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',

};
