export const UsersAddDevicesActionTypes = {
    GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER: '@@/layout/GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER',
    GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_LOADING: '@@/layout/GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_LOADING',
    GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_SUCCESS: '@@/layout/GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_SUCCESS',
    GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_ERROR: '@@/layout/GET_ADD_DEVICES_GET_BY_SELECT_ASSETS_USER_ERROR',

    POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER: '@@/layout/POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER',
    POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_LOADING: '@@/layout/POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_LOADING',
    POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_SUCCESS: '@@/layout/POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_SUCCESS',
    POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_ERROR: '@@/layout/POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_ERROR',
    POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_RESET: '@@/layout/POST_ADD_DEVICES_POST_BY_SELECT_ASSETS_USER_RESET'

}