import { AdminNdSiteAdminAssetsActionTypes } from './constants';

const GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    getAssetsByCompanyIdAdmin: [],
    loading: false,

};
const GET_SOFTWARE_LIST_INITIAL_STATE = {
    getSoftwareList: [],
    loading: false,
};

const ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    addAssetsByCompanyIdAdmin: '',
    loading: false,
};

const EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    editAssetsByCompanyIdAdmin: '',
    loading: false,
};

const STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    statusAssetsByCompanyIdAdmin: '',
    loading: false,
};

const CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    csvAssetsByCompanyIdAdmin: '',
    loading: false,
};

const DELETE_ASSETS_INITIAL_STATE = {
    deleteAsset: '',
    loading: false,
};

const getAssetsByCompanyIdAdminNdSiteAdminReducer = (
    state = GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getAssetsByCompanyIdAdmin: state.getAssetsByCompanyIdAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getAssetsByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getAssetsByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};
const getSoftwareListReducer = (
    state = GET_SOFTWARE_LIST_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_LOADING:
            return {
                getSoftwareList: state.getSoftwareList,
                loading: true,
            };
        case AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_SUCCESS:
            return {
                getSoftwareList: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.GET_SOFTWARE_LIST_ERROR:
            return {
                getSoftwareList: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_SOFTWARE_LIST_INITIAL_STATE;
        default:
            return state;
    }
};

const deleteAssetsReducer = (
    state = DELETE_ASSETS_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_LOADING:
            return {
                deleteAsset: state.deleteAsset,
                loading: true,
            };
        case AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_SUCCESS:
            return {
                deleteAsset: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_ERROR:
            return {
                deleteAsset: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.DELETE_ASSETS_RESET:
            return DELETE_ASSETS_INITIAL_STATE;
        default:
            return state;
    }
};

const addAssetsByCompanyIdAdminNdSiteAdminReducer = (
    state = ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                addAssetsByCompanyIdAdmin: state.addAssetsByCompanyIdAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                addAssetsByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                addAssetsByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const editAssetsByCompanyIdAdminNdSiteAdminReducer = (
    state = EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                editAssetsByCompanyIdAdmin: state.editAssetsByCompanyIdAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                editAssetsByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                editAssetsByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const statusAssetsByCompanyIdAdminNdSiteAdminReducer = (
    state = STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                statusAssetsByCompanyIdAdmin: state.statusAssetsByCompanyIdAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                statusAssetsByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                statusAssetsByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const csvAssetsByCompanyIdAdminNdSiteAdminReducer = (
    state = CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                csvAssetsByCompanyIdAdmin: state.csvAssetsByCompanyIdAdmin,
                loading: true,
            };
        case AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                csvAssetsByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                csvAssetsByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminAssetsActionTypes.CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    getAssetsByCompanyIdAdminNdSiteAdminReducer,
    addAssetsByCompanyIdAdminNdSiteAdminReducer,
    editAssetsByCompanyIdAdminNdSiteAdminReducer,
    statusAssetsByCompanyIdAdminNdSiteAdminReducer,
    csvAssetsByCompanyIdAdminNdSiteAdminReducer,
    getSoftwareListReducer,
    deleteAssetsReducer
};
