import { AdminNdSiteAdminDataMngntActionTypes } from './constants';

export const getDataMngmntAdminNdSiteAdminAction = (data): LayoutAction => ({
    type: AdminNdSiteAdminDataMngntActionTypes.GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
    data,
});

export const addDataMngmntAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
    data,
});

export const editDataMngmntAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
    data,
});

export const csvDataMngmntAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
    data,
});

export const statusDataMngmntAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
    data,
});

export const assignGroupsDataMngmntAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
    data,
});

export const getGroupsDataMngmntAdminNdSiteAdminActions = (id): LayoutAction => ({
    type: AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
    id,
});

export const addGroupsDataMngmntAssAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN,
    data,
});

export const stateEmptydAction = (id): LayoutAction => ({
    type: AdminNdSiteAdminDataMngntActionTypes.STATE_EMPTY_INTIAL_STATE,

});

