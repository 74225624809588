import { APICore } from '../../../../helpers/api/apiCore';
import * as URL from '../../../../constants/endPoint';

const api = new APICore();

function adminNdSiteAdminPostAddSoftwareEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.POST_SOFTWARE, data);
}

function adminNdSiteAdminGetSoftwareEndPoint(params: any): any {
    const { companyId, search, limit, page } = params?.data;
    return api.get(`${URL.GET_SOFTARE}companyId=${companyId}&search=${search}&limit=${limit}&page=${page}`);
}

function getItDashboardEndPoint(params: any): any {
    const { id } = params?.data;
    return api.get(`${URL.GET_IT_DASHBOARD}${id}`);
}
function ipAddressAdminNdSiteAdminEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.POST_IP_ADDRESS, data);
}

function getTotalSoftwareAssetsEndPoint(params: any): any {
    const { companyId } = params?.data;
    return api.get(`${URL.TOAT_SOFTWARE_ASSETS_VULN}${companyId}`);
}

function getActiveUsers(params: any): any {
    const { data } = params;
    return api.get(URL.GET_ACTIVE_USERS, data);
}

function adminNdSiteAdminGetWithAndWithOutvulnerabilityEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.POST_VULNERABILITIES_DEFAULT, data);
}



export {
    adminNdSiteAdminPostAddSoftwareEndPoint,
    adminNdSiteAdminGetSoftwareEndPoint,
    getItDashboardEndPoint,
    ipAddressAdminNdSiteAdminEndPoint,
    getTotalSoftwareAssetsEndPoint,
    getActiveUsers,
    adminNdSiteAdminGetWithAndWithOutvulnerabilityEndPoint,
};
