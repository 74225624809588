// @flow
import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import getMyDevicesGetByAssetsSaga from './user/devices/myDevices/saga';
import getAddDevicesGetByAssetsSaga from './user/devices/addDevices/saga';
import getMyDataSourceByGroupsSaga from './user/dataSources/myDataSources/saga';
import getInstanceAdminNdSiteAdminSaga from './adminNdSideAdmin/instance/saga';
import getCompanyAdminNdSiteAdminSaga from './adminNdSideAdmin/company/saga';
import getAssetsByCompanyIdAdminNdSiteAdminSaga from './adminNdSideAdmin/assets/saga';
import getDataMngmntAdminNdSiteAdminSaga from './adminNdSideAdmin/dataManagement/saga';
import getAccountsByCompanyIdAdminNdSiteAdminSaga from './adminNdSideAdmin/accounts/saga';
import getLogsAdminNdSiteAdminSaga from './adminNdSideAdmin/logs/sagas';
import getGroupsListAdminNdSiteAdminSaga from './adminNdSideAdmin/groups/saga';
import getNewFildSaga from './user/dashboard/newsFild/saga';
import profileUpdateSaga from './adminNdSideAdmin/adminSetting/saga';
import putForcePasswordSaga from './forcePassword/saga';
import postAddSoftwareAdminNdSiteAdminSaga from './adminNdSideAdmin/dashboard/addSoftware/saga';
import getSoftwareSaga from './adminNdSideAdmin/software/saga';
import getTaskListSaga from './adminNdSideAdmin/task/saga';
import emailVerifySaga from './adminNdSideAdmin/emailVerify/saga';
import getIpAddressSaga from './adminNdSideAdmin/IPAddress/saga';
import AdminSaga from './adminNdSideAdmin/adminCreate/saga';
import getRiskdashboardSaga from './adminNdSideAdmin/riskDashboard/saga';
import getDocumentsSiteAdminSaga from './adminNdSideAdmin/documents/saga';
import getVChartByCompanyIdAdminNdSiteAdminSaga from './adminNdSideAdmin/VulnerabilityChart/saga'

export default function* rootSaga(): any {
    yield all([
        authSaga(),
        layoutSaga(),
        getMyDevicesGetByAssetsSaga(),
        getAddDevicesGetByAssetsSaga(),
        getMyDataSourceByGroupsSaga(),
        getInstanceAdminNdSiteAdminSaga(),
        getCompanyAdminNdSiteAdminSaga(),
        getAssetsByCompanyIdAdminNdSiteAdminSaga(),
        getDataMngmntAdminNdSiteAdminSaga(),
        getAccountsByCompanyIdAdminNdSiteAdminSaga(),
        getLogsAdminNdSiteAdminSaga(),
        getGroupsListAdminNdSiteAdminSaga(),
        getNewFildSaga(),
        profileUpdateSaga(),
        putForcePasswordSaga(),
        postAddSoftwareAdminNdSiteAdminSaga(),
        getSoftwareSaga(),
        getTaskListSaga(),
        emailVerifySaga(),
        getIpAddressSaga(),
        AdminSaga(),
        getRiskdashboardSaga(),
        getDocumentsSiteAdminSaga(),
        getVChartByCompanyIdAdminNdSiteAdminSaga()
    ]);
}
