import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminCompanyActionTypes } from './constants';
import { adminNdSiteAdminComapnyApiEndPoint } from './api';

// start cash day Report

function* callGetCompanyByAdminNdSiteAdmin(id) {
    try {
        yield put({
            type: AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminComapnyApiEndPoint);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

// end cash day Report List

export function* getCompanyAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminCompanyActionTypes.GET_COMAPNY_ADMIN_ND_SITE_ADMIN,
        callGetCompanyByAdminNdSiteAdmin
    );
}

function* getCompanyAdminNdSiteAdminSaga(): any {
    yield all([fork(getCompanyAdminNdSiteAdminAction)]);
}

export default getCompanyAdminNdSiteAdminSaga;
