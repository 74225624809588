import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function getDashboardEndPoint(params: any): any {
    const { companyId } = params?.data;
    return api.create(URL.GET_RISKDASHBOARD, { companyId });
}


function getVulnerabilityEndPoint(params: any): any {
    const { company_id } = params?.data;
    return api.get(`${URL.GET_RISK_VULNERABILITY}company_id=${company_id}`);
}

function riskRemedationEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.POST_RISK_REMEDATION, data);
}
function japaneseRemedationEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.POST_REMEDATION_JAPANESE, data);
}
export { getDashboardEndPoint, getVulnerabilityEndPoint, riskRemedationEndPoint, japaneseRemedationEndPoint };
