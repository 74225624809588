import { AdminNdSiteAdminInstanceActionTypes } from './constants';

export const getInstanceAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_ADMIN_ND_SITE_ADMIN,
    data,
});

export const getInstancePlans = (data): LayoutAction => ({
    type: AdminNdSiteAdminInstanceActionTypes.GET_INSTANCE_PLAN,
    data,
});

export const addInstanceAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminInstanceActionTypes.ADD_INSTANCE_ADMIN_ND_SITE_ADMIN,
    data,
});

export const editInstanceAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminInstanceActionTypes.EDIT_INSTANCE_ADMIN_ND_SITE_ADMIN,
    data,
});

export const statusInstanceAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminInstanceActionTypes.STATUS_INSTANCE_ADMIN_ND_SITE_ADMIN,
    data,
});

export const deleteInstanceAdminNdSiteAdminActions = (id): LayoutAction => ({
    type: AdminNdSiteAdminInstanceActionTypes.DELETE_INSTANCE_ADMIN_ND_SITE_ADMIN,
    id,
});
export const addLicenceAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminInstanceActionTypes.ADD_LICENCE_ADMIN_ND_SITE_ADMIN,
    data,
});
export const activeUserAdminNdSiteAdminActions = (data): LayoutAction => ({
    type: AdminNdSiteAdminInstanceActionTypes.ACTIVE_USER_ADMIN_ND_SITE_ADMIN,
    data,
});
