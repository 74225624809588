import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';
const api = new APICore();

function getIpAddressEndPoint(params: any): any {
    const { search, page, limit } = params?.data;
    return api.get(`${URL.GET_IP_ADDRESS}search=${search}&page=${page}&limit=${limit}`);
}

function getIpAddressInfoEndPoint(params: any): any {
    const { id } = params;
    return api.get(URL.GET_IP_ADDRESS_INFO + id);
}
function statusIpAddressEndPoint(params: any): any {
    const { data } = params;
    return api.update(URL.STATUS_IP_ADDRESS, data);
}

function getIpAddressScanEndPoint(params: any): any {
    const { searchTerm, page, limit } = params?.data;
    return api.get(`${URL.GET_IP_ADDRESS_SCAN_SCHLDULE}search=${searchTerm}&limit=${limit}&page=${page}`);
}

function getPreviousIpAddressScanListEndPoint(params: any): any {
    const { companyId, ipId } = params?.data;
    return api.get(`${URL.GET_PREVIOUS_IP_ADDRESS_SCAN_LIST}company_id=${companyId}&ip_id=${ipId}`);
}

function getIpAddressScanListEndPoint(params: any): any {
    return api.get(URL.GET_IP_ADDRESS_SCAN_SCHLDULE_LIST);
}
function addIpScanScheduleEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.ADD_IP_ADDRESS_SCAN_SCHLDULE, data);
}
function editIpScanScheduleEndPoint(params: any): any {
    const { data } = params;
    return api.update(URL.EDIT_IP_ADDRESS_SCAN_SCHLDULE, data);
}
function addIpScanEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.ADD_IP_ADDRESS_ON_SCAN_SCHLDULE, data);
}
export {
    getIpAddressEndPoint,
    getIpAddressInfoEndPoint,
    getIpAddressScanEndPoint,
    getPreviousIpAddressScanListEndPoint,
    addIpScanScheduleEndPoint,
    editIpScanScheduleEndPoint,
    getIpAddressScanListEndPoint,
    statusIpAddressEndPoint,
    addIpScanEndPoint
};
