import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminDataMngntActionTypes } from './constants';
import {
    adminNdSiteAdminGetDataMngmntEndPoint,
    adminNdSiteAdminAddDataMngmntEndPoint,
    adminNdSiteAdminEditDataMngmntEndPoint,
    adminNdSiteAdminCsvDataMngmntEndPoint,
    adminNdSiteAdminStatusDataMngmntEndPoint,
    adminNdSiteAdminAssignGroupsDataMngmntEndPoint,
    adminNdSiteAdminGetGroupsDataMngmntEndPoint,
    adminNdSiteAdminAddGroupsDataMngmntAssetsEndPoint,
} from './api';

// start cash day Report

function* callGetDataMngmntAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminGetDataMngmntEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* calladdDataMngmntAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAddDataMngmntEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callEditDataMngmntAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminEditDataMngmntEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callCsvDataMngmntAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminCsvDataMngmntEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStatusDataMngmntAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminStatusDataMngmntEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callAssignGroupsDataMngmntAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAssignGroupsDataMngmntEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            // yield put({
            //     type: AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
            //     payload: {},
            // });
        } else {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callGetGroupsDataMngmntAdminNdSiteAdmin(id) {
    try {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminGetGroupsDataMngmntEndPoint, id);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            // yield put({
            //     type: AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
            //     payload: {},
            // });
        } else {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callAddGroupsDataMngmntAssetsAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminAddGroupsDataMngmntAssetsEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
        yield put({
            type: AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN_RESET,
            payload: {},
        });
    }
}

function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminDataMngntActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });

}


// end cash day Report List

export function* getDataMngmntAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminDataMngntActionTypes.GET_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
        callGetDataMngmntAdminNdSiteAdmin
    );
}

export function* addDataMngmntAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminDataMngntActionTypes.ADD_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
        calladdDataMngmntAdminNdSiteAdmin
    );
}

export function* editDataMngmntAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminDataMngntActionTypes.EDIT_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
        callEditDataMngmntAdminNdSiteAdmin
    );
}

export function* csvDataMngmntAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminDataMngntActionTypes.CSV_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
        callCsvDataMngmntAdminNdSiteAdmin
    );
}

export function* statusDataMngmntAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminDataMngntActionTypes.STATUS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
        callStatusDataMngmntAdminNdSiteAdmin
    );
}

export function* assignGroupsDataMngmntAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminDataMngntActionTypes.ASSIGN_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
        callAssignGroupsDataMngmntAdminNdSiteAdmin
    );
}

export function* getGroupsDataMngmntAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminDataMngntActionTypes.GET_GROUPS_DATA_MNGMNT_ADMIN_ND_SITE_ADMIN,
        callGetGroupsDataMngmntAdminNdSiteAdmin
    );
}

export function* addGroupsDataMngmntAssetsAdminNdSiteAdminAction(): any {
    yield takeEvery(
        AdminNdSiteAdminDataMngntActionTypes.ADD_GROUPS_DATA_MNGMNT_ASS_ADMIN_ND_SITE_ADMIN,
        callAddGroupsDataMngmntAssetsAdminNdSiteAdmin
    );
}

export function* stateEmptyAction(): any {
    yield takeEvery(
        AdminNdSiteAdminDataMngntActionTypes.STATE_EMPTY_INTIAL_STATE,
        callStateEmpty
    );
}


function* getDataMngmntAdminNdSiteAdminSaga(): any {
    yield all([
        fork(getDataMngmntAdminNdSiteAdminAction),
        fork(addDataMngmntAdminNdSiteAdminAction),
        fork(editDataMngmntAdminNdSiteAdminAction),
        fork(csvDataMngmntAdminNdSiteAdminAction),
        fork(statusDataMngmntAdminNdSiteAdminAction),
        fork(assignGroupsDataMngmntAdminNdSiteAdminAction),
        fork(getGroupsDataMngmntAdminNdSiteAdminAction),
        fork(addGroupsDataMngmntAssetsAdminNdSiteAdminAction),
        fork(stateEmptyAction)
    ]);
}

export default getDataMngmntAdminNdSiteAdminSaga;
