import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminLogsActionTypes } from './constants';
import {
    adminNdSiteAdminGetLogsEndPoint,
} from './api';


// start cash day Report

function* callGetLogsAdminNdSiteAdmin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(adminNdSiteAdminGetLogsEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}


function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminLogsActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });

}


// end cash day Report List


export function* getLogsAdminNdSiteAdminAction(): any {
    yield takeEvery(AdminNdSiteAdminLogsActionTypes.GET_LOGS_ADMIN_ND_SITE_ADMIN, callGetLogsAdminNdSiteAdmin);

}

export function* stateEmptyAction(): any {
    yield takeEvery(
        AdminNdSiteAdminLogsActionTypes.STATE_EMPTY_INTIAL_STATE,
        callStateEmpty
    );
}


function* getLogsAdminNdSiteAdminSaga(): any {
    yield all([
        fork(getLogsAdminNdSiteAdminAction),
        fork(stateEmptyAction)

    ]);
}

export default getLogsAdminNdSiteAdminSaga;
