import { AdminNdSiteAdminVChartActionTypes } from './constants';

const GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    getVChartByCompanyIdAdmin: [],
    loading: false,
};

const ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    addVChartByCompanyIdAdmin: '',
    loading: false,
};

const EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    editVChartByCompanyIdAdmin: '',
    loading: false,
};

const STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    statusVChartByCompanyIdAdmin: '',
    loading: false,
};

const CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE = {
    csvVChartByCompanyIdAdmin: '',
    loading: false,
};

const getVChartByCompanyIdAdminNdSiteAdminReducer = (
    state = GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getVChartByCompanyIdAdmin: state.getVChartByCompanyIdAdmin,
                loading: true,
            };
        case AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getVChartByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminVChartActionTypes.GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getVChartByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminVChartActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const addVChartByCompanyIdAdminNdSiteAdminReducer = (
    state = ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                addVChartByCompanyIdAdmin: state.addVChartByCompanyIdAdmin,
                loading: true,
            };
        case AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                addVChartByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                addVChartByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminVChartActionTypes.ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return ADD_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const editVChartByCompanyIdAdminNdSiteAdminReducer = (
    state = EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                editVChartByCompanyIdAdmin: state.editVChartByCompanyIdAdmin,
                loading: true,
            };
        case AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                editVChartByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                editVChartByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminVChartActionTypes.EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return EDIT_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const statusVChartByCompanyIdAdminNdSiteAdminReducer = (
    state = STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                statusVChartByCompanyIdAdmin: state.statusVChartByCompanyIdAdmin,
                loading: true,
            };
        case AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                statusVChartByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                statusVChartByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminVChartActionTypes.STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return STATUS_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

const csvVChartByCompanyIdAdminNdSiteAdminReducer = (
    state = CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                csvVChartByCompanyIdAdmin: state.csvVChartByCompanyIdAdmin,
                loading: true,
            };
        case AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                csvVChartByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                csvVChartByCompanyIdAdmin: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminVChartActionTypes.CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
            return CSV_VCHART_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    getVChartByCompanyIdAdminNdSiteAdminReducer,
    addVChartByCompanyIdAdminNdSiteAdminReducer,
    editVChartByCompanyIdAdminNdSiteAdminReducer,
    statusVChartByCompanyIdAdminNdSiteAdminReducer,
    csvVChartByCompanyIdAdminNdSiteAdminReducer,
};
