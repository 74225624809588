// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
    forgotPasswordConfirm,
} from '../../helpers/';

import { APICore, setAuthorization } from '../../helpers/api/apiCore';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';
import { Defaultlogout } from '../../helpers/api/auth';

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
// function* login({ payload: { username, password } }) {
//     try {
//         const response = yield call(loginApi, { username, password });
//         const user = response.data;
//         // NOTE - You can change this according to response format from your api
//         api.setLoggedInUser(user);
//         setAuthorization(user['token']);
//         yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
//     } catch (error) {
//         yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
//         api.setLoggedInUser(null);
//         setAuthorization(null);
//     }
// }

function* login(data) {
    const loginData = data?.payload;
    try {
        const response = yield call(loginApi, loginData);
        const usersName = response?.data?.user?.fullName;
        const usersRoles = response?.data?.user?.role;
        const companyId = response?.data?.user?.company_id;
        const token = response?.data?.token;
        localStorage.setItem('imagedefault',response?.data?.user?.picture);
        const user = {
            data: response,
            image: response?.data?.user,
            id: 1,
            username: usersName,
            password: 'test',
            firstName: usersName,
            lastName: 'User',
            role: usersRoles,
            cmId: companyId,
            token: token,
        };
        // NOTE - You can change this according to response format from your api
        api.setLoggedInUser(user);
        setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout(data) {
    const logOut = data.payload;
    try {
        yield call(logoutApi, logOut);
        api.setLoggedInUser(null);
        setAuthorization(null);
        localStorage.clear();
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* watchDefaultlogout(data) {
    try {
        const response = yield call(Defaultlogout, data);
        setAuthorization(null);
        localStorage.clear();
    } catch (error) {
    }
}

function* signup({ payload: { fullname, email, password } }) {
    try {
        const response = yield call(signupApi, { fullname, email, password });
        const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword(data) {
    try {
        const response = yield call(forgotPasswordApi, data.payload.email);
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

function* forgotPasswordChange({ payload: { data } }) {
    try {
        const response = yield call(forgotPasswordConfirm, data);
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD_CHANGE, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error));
    }
}

export function* watchLoginUser(): any {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout(): any {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchForgotPasswordChange(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
}

export function* watchLogoutDefault(): any {
    yield takeEvery(AuthActionTypes.DEFAULT_LOGOUT_FIRST, watchDefaultlogout);
}

function* authSaga(): any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogout),
        fork(watchSignup),
        fork(watchForgotPassword),
        fork(watchForgotPasswordChange),
        fork(watchLogoutDefault),
    ]);
}

export default authSaga;
